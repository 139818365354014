import 'patient/styles/main-screen.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import seeDoctors from 'shared/assets/images/see-doctors.png';
import medicalFile from 'shared/assets/images/medical-file.png';
import onlineConsultation from 'shared/assets/images/online-consultation.png';
import recomandareRetata from 'shared/assets/images/recomandare-reteta.png';
import howItWorksImg1 from 'shared/assets/images/how-it-works-img-1.png';
import howItWorksImg2 from 'shared/assets/images/how-it-works-img-2.png';
import howItWorksImg3 from 'shared/assets/images/how-it-works-img-3.png';
import imageBenefit1 from 'shared/assets/images/homepage-patient-benefit1.svg';
import imageBenefit2 from 'shared/assets/images/homepage-patient-benefit2.svg';
import imageBenefit3 from 'shared/assets/images/homepage-patient-benefit3.svg';
import imageBenefit4 from 'shared/assets/images/homepage-patient-benefit4.svg';
import imageBenefit5 from 'shared/assets/images/homepage-patient-benefit5.svg';
import imageBenefit6 from 'shared/assets/images/homepage-patient-benefit6.svg';

import demoAccordionCollapsible0 from 'shared/assets/images/Status-Online.png';
import demoAccordionCollapsible1 from 'shared/assets/images/Status-Busy.png';
import demoAccordionCollapsible2 from 'shared/assets/images/Status-Offline.png';
import backgroundMobile from 'shared/assets/images/Background_Mobile_Imagex2.png';
import doctorApp from 'shared/assets/images/doctor-app.png';
import mail from 'shared/assets/icons/Mail.svg';
import aiDoctor from 'shared/assets/images/Avatar-Ai.png';
import aiDoctorMobile from 'shared/assets/images/Avatar-Ai-mobile.png';
import diagnosticAiIcon from 'shared/assets/icons/Diagnostic-AI.svg';
import rightCustomArrow from 'shared/assets/icons/Right-Custom-Arrow.svg';

import googlePlayGetItOnMobile from 'shared/assets/images/googlePlayGetItOn-Mobile.png';
import downloadOnTheAppStoreMobile from 'shared/assets/images/downloadOnTheAppStore-Mobile.png';

import demoAccordionMobile1 from 'shared/assets/images/demo-accordion-mobile1.png';
import demoAccordionMobile2 from 'shared/assets/images/demo-accordion-mobile2.png';
import demoAccordionMobile3 from 'shared/assets/images/demo-accordion-mobile3.png';
import hiw1 from 'shared/assets/images/hiw-1.png';
import hiw2 from 'shared/assets/images/hiw-2.png';
import hiw3 from 'shared/assets/images/hiw-3.png';
import hiw4 from 'shared/assets/images/hiw-4.png';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Accordion, Modal } from 'react-bootstrap';
import Carousel from 'react-grid-carousel';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import _ from 'lodash';
import Utils from 'shared/modules/Utils';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { isDoctor } from 'shared/services/EnvService';
import {
  getAllReviewsState,
  getAuthState,
  getAvailableDoctorsState,
  getPatientAppointmentState,
  getSpecializationsState,
} from 'shared/redux/src/StatesGetter';
import { getSpecializationsRequest } from 'shared/redux/actions/SpecializationActions';
import { fetchAllReviewsRequest } from 'shared/redux/actions/AllReviewsActions';
import { Rating } from 'react-simple-star-rating';
import { getAvailableDoctorsRequest } from 'shared/redux/actions/DoctorsActions';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import { Helmet } from 'react-helmet';
import LazyImage from 'shared/components/LazyImage';
import * as DOMPurify from 'dompurify';
import appleStoreIcon from 'shared/assets/icons/apple-store-icon.svg';
import googlePlayStoreIcon from 'shared/assets/icons/google-playstore-icon.svg';
import PatientStickyBar from './PatientStickyBar';
import ThemeExporter from '../../shared/themes/Themes';
import { appointmentLocalStepsRequest } from '../../shared/redux/actions/PatientAppointmentActions';
import PublicHeaderMenu from '../../shared/components/PublicHeaderMenu';
import star from '../../shared/assets/icons/Star.svg';
import PublicReputationSlider from '../../shared/components/PublicReputationSlider';
import magnifier from '../../shared/assets/icons/Magnifier.svg';
import downloadOnTheAppStore from '../../shared/assets/images/download-on-the-app-store.png';
import googlePlayGetItOn from '../../shared/assets/images/google-play-get-it-on.png';
import PublicReviewsSlider from '../../shared/components/PublicReviewsSlider';
import PublicFooterSection from '../../shared/components/PublicFooterSection';

const PatientMainScreenComponent = ({ page = 'homepage' }) => {
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sanitizer = DOMPurify.sanitize;
  const [activeDemoAccordionSlideIndex, setActiveDemoAccordionSlideIndex] = useState<any>('0');
  const demoImages = {
    demoAccordionCollapsible0,
    demoAccordionCollapsible1,
    demoAccordionCollapsible2,
  };
  const specializations = useSelector(getSpecializationsState, shallowEqual);
  const { total: doctorsCounter } = useSelector(getAvailableDoctorsState, shallowEqual);
  const baseUrl = window.location.origin;
  useEffect(() => {
    dispatch(
      getSpecializationsRequest({
        searchName: '',
      }),
    );
    dispatch(fetchAllReviewsRequest({}));

    const anchor = window.location.hash.slice(1);
    setTimeout(() => {
      if (anchor) {
        const anchorEl = document.getElementById(anchor);
        if (anchorEl) {
          anchorEl.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth',
        // });
      }
    }, 1000);
  }, []);

  const mailChimpSubmitButton = document.querySelector('.mailchimp-form button');
  if (mailChimpSubmitButton) {
    mailChimpSubmitButton.innerHTML = t('homepage.patient.newsletter.submit');
  }

  const mailChimpInputField = document.querySelector('.mailchimp-form input');
  if (mailChimpInputField) {
    // @ts-ignore
    mailChimpInputField.placeholder = t('email');
  }

  const handleDoctorSpecializationSubmit = (name) => {
    navigate(
      generatePath('/patient/medicList/:specialization', {
        specialization: name,
      }),
    );
  };

  let patientAppStoreLink;
  if (!isDoctor(true)) {
    if (Utils.isiPhone()) {
      patientAppStoreLink = 'itms-apps://apps.apple.com/ro/app/ringdoc/id1574488837';
    } else {
      patientAppStoreLink = 'https://apps.apple.com/ro/app/ringdoc/id1574488837';
    }
  }
  const renderArrowLeft = () => {
    return (
      <div className="prev-slider">
        <CustomIcon
          className="custom-icon rotate-180"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };
  const renderArrowRight = () => {
    return (
      <div className="next-slider">
        <CustomIcon
          className="custom-icon"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };

  const benefitsArray = [
    {
      id: 1,
      iconName: imageBenefit1,
      title: t('homepage.patient.benefits.benefit1'),
      iconColor: '#FF6A39',
      bgColor: 'rgba(255, 106, 57, 0.1)',
    },
    {
      id: 2,
      iconName: imageBenefit2,
      title: t('homepage.patient.benefits.benefit2'),
      iconColor: '#6AC684',
      bgColor: 'rgba(106, 198, 132, 0.1)',
    },
    {
      id: 3,
      iconName: imageBenefit3,
      title: t('homepage.patient.benefits.benefit3'),
      iconColor: '#00CFB4',
      bgColor: 'rgba(0, 207, 180, 0.1)',
    },
    {
      id: 4,
      iconName: imageBenefit4,
      title: t('homepage.patient.benefits.benefit4'),
      iconColor: '#FFA3BF',
      bgColor: 'rgba(255, 163, 191, 0.1)',
    },
    {
      id: 5,
      iconName: imageBenefit5,
      title: t('homepage.patient.benefits.benefit5'),
      iconColor: '#F4B700',
      bgColor: 'rgba(244, 183, 0, 0.1)',
    },
    {
      id: 6,
      iconName: imageBenefit6,
      title: t('homepage.patient.benefits.benefit6'),
      iconColor: '#9F8FFF',
      bgColor: 'rgba(159, 143, 255, 0.1)',
    },
  ];

  const howItWorks1 = (
    <div className="box box1">
      <div className="row">
        <div className="col-12 d-flex justify-content-center flex-column">
          <div className="circle one">
            <span>1</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step1Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step1SubTitle')}</div>
          <div className="apps-button">
            <a target="_blank" href={patientAppStoreLink} rel="noreferrer">
              <i className="app-store-icon cursor-pointer" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
              rel="noreferrer"
            >
              <i className="google-play-icon cursor-pointer" />
            </a>
          </div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg1}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  const howItWorks2 = (
    <div className="box box2">
      <div className="row">
        <div className="col-12 d-flex justify-content-center flex-column box2FlexG">
          <div className="circle two">
            <span>2</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step2Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step2SubTitle')}</div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg2}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  const howItWorks3 = (
    <div className="box box3">
      <div className="row">
        <div className="col-12 d-flex justify-content-center flex-column">
          <div className="circle three">
            <span>3</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step3Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step3SubTitle')}</div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg3}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(getAvailableDoctorsRequest({}));
  }, [doctorsCounter]);

  const CustomNewsletterForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () => {
      return (
        email &&
        onValidated({
          EMAIL: email.value,
        })
      );
    };

    return (
      <div className="mailchimp-form">
        {status === 'error' && (
          <div
            className="error-response"
            dangerouslySetInnerHTML={{ __html: sanitizer(t('mailchimpErrorMessage')) }}
          />
        )}
        {status === 'success' && (
          <div
            className="success-response"
            // eslint-disable-next-line max-len
            dangerouslySetInnerHTML={{ __html: sanitizer(t('mailchimpConfirmationMessage')) }}
          />
        )}
        <input
          className="mailchimp-form-email-input form-control"
          ref={(node) => {
            email = node;
            return email;
          }}
          type="email"
          placeholder={t('email')}
        />
        {/* eslint-disable-next-line react/button-has-type */}
        <button className="mailchimp-form-button" onClick={submit}>
          {t('homepage.patient.newsletter.submit')}
        </button>
      </div>
    );
  };

  const redirectToMedicList = () => {
    navigate('/patient/medicList');
  };

  const [showModalVideo, setModalVideoShow] = useState(false);
  const modalVideoShow = () => setModalVideoShow(true);
  const modalVideoClose = () => setModalVideoShow(false);
  const theme = ThemeExporter.PatientTheme();
  const {
    callStatus,
    stickyStatus,
    doctor = {},
    startTime,
    endTime,
    isInitialTime = true,
  } = useSelector(getPatientAppointmentState, shallowEqual);
  const timeUp = () => {
    dispatch(appointmentLocalStepsRequest({ isInitialTime: false }));
  };
  const returnToCall = () => {
    if (localStorage.getItem('Answer_Call') !== 'true') {
      navigate('/patient/consultations');
    } else {
      dispatch(appointmentLocalStepsRequest({ callStatus: 'active' }));
      navigate('/patient/video-call');
    }
  };

  return (
    <div className={page === 'isLanding' ? 'main-screen-page-is-landing' : 'main-screen-page'}>
      <Helmet>
        <meta property="og:url" content="URL-ul paginii" />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Titlul meta al paginii sau h1" />
        <meta property="og:description" content="Continutul meta description" />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>
      {isLoggedIn && (
        <PatientStickyBar
          theme={theme}
          callStatus={callStatus}
          stickyStatus={stickyStatus}
          doctor={doctor}
          startTime={startTime}
          endTime={endTime}
          isInitialTime={isInitialTime}
          timeUp={timeUp}
          returnToCall={returnToCall}
        />
      )}

      {!isMobile && (
        <div className="not-mobile">
          {/* MAIN HERO */}
          <section className="main-hero-1">
            <div className="main-hero-1-main">
              <div className="main-hero-1-section-like-menu">
                <div className="main-hero-1-container-menu">
                  {page === 'homepage' && <PublicHeaderMenu />}
                </div>
              </div>
              <div className="main-hero-1-section">
                <div className="main-hero-1-container">
                  <div className="main-hero-1-container-content">
                    <div className="sessions">
                      <strong>+7000</strong> sesiuni finalizate
                    </div>
                    <div className="content-h1">Un medic bun, mereu aproape.</div>
                    <div className="content-text">
                      Conectează-te rapid cu medici de încredere pentru consultații online. Primești
                      diagnostic, recomandări personalizate și rețetă medicală.
                    </div>
                    <div className="content-btn">
                      <Link className="cursor-pointer" to="/medici" key="medici" reloadDocument>
                        <span className="orange-button-new" onClick={() => {}}>
                          Discută cu un medic
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-hero-1-section-like-store">
              <div className="main-hero-1-section-like-store-container">
                <div className="main-hero-1-section-like-store-info">
                  <div className="title">Acces rapid, de oriunde.</div>
                  <div className="content">
                    Îngrijire medicală la îndemână, cu o experiență optimizată pentru mobil.
                  </div>
                </div>
                <div className="main-hero-1-container-store-btns">
                  <a target="_blank" href={patientAppStoreLink} rel="noreferrer">
                    <div className="store">
                      <div className="btn">
                        <img
                          src={appleStoreIcon}
                          alt="doctor not found"
                          style={{
                            width: '29px',
                            height: '36px',
                            marginRight: '8px',
                          }}
                        />
                        <div className="btn-text">
                          <div className="btn-text-info">Disponibil în</div>
                          <div className="btn-text-store">App Store</div>
                        </div>
                      </div>
                      <div className="app-rating">
                        <div className="app-rating-star">
                          <img
                            src={star}
                            alt="star"
                            style={{
                              width: '28px',
                              height: '28px',
                              marginRight: '8px',
                            }}
                          />
                        </div>
                        <div className="app-rating-rating">
                          <span className="rating-value">4.6</span>/5
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="separator" />
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
                    rel="noreferrer"
                  >
                    <div className="store store-google">
                      <div className="btn">
                        <img
                          src={googlePlayStoreIcon}
                          alt="Google Play Store Icon"
                          style={{
                            width: '29px',
                            height: '36px',
                            marginRight: '8px',
                          }}
                        />
                        <div className="btn-text">
                          <div className="btn-text-info">Disponibil în</div>
                          <div className="btn-text-store">Google Play</div>
                        </div>
                      </div>
                      <div className="app-rating">
                        <div className="app-rating-star">
                          <img
                            src={star}
                            alt="star"
                            style={{
                              width: '28px',
                              height: '28px',
                              marginRight: '8px',
                            }}
                          />
                        </div>
                        <div className="app-rating-rating">
                          <span className="rating-value">4.1</span>/5
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="ai">
            <div className="ai-container">
              <div className="ai-info">
                <div className="pre-header">
                  <img src={diagnosticAiIcon} alt="diagnosticA" />
                  <div className="pre-header-info">Diagnostic AI</div>
                </div>
                <div className="ai-header">Află rapid ce se întâmplă cu tine</div>
                <div className="ai-content">
                  Discută ca și cum ai face-o cu un medic, alături de un asistent AI specializat și
                  verificat de medici. Descoperă posibile diagnostice, interpretări ale analizelor
                  sau informații esențiale despre sănătatea ta.*
                </div>
                <div className="ai-start-conversation">
                  <div className="white-button-new" onClick={() => {}}>
                    <div className="white-button-new-text">Începe conversația</div>
                    <img src={rightCustomArrow} alt="diagnosticAI" />
                  </div>
                </div>
                <div className="note">
                  *Asistentul AI nu înlocuiește un consult medical și nu poate oferi un diagnostic
                  oficial. Răspunsurile sale se bazează pe date statistice și trebuie luate doar ca
                  recomandări orientative. Pentru un diagnostic cert și îngrijire adecvată, consultă
                  un medic specialist.
                </div>
              </div>
              <div className="ai-dr-img">
                <img src={aiDoctor} alt="diagnosticAI" />
              </div>
            </div>
          </section>

          {/* Reputation Hospitals */}
          <PublicReputationSlider />

          {/* Main information */}
          <section className="main-informtion">
            <div className="main-informtion-container">
              <div className="title">
                Când ai nevoie de un medic, <br />
                indiferent unde te afli.
              </div>
              <div className="content">
                Fie că vrei să discuți cu doctorul tău, cauți un specialist pentru un consult rapid
                sau ai nevoie de o a doua opinie medicală, poți obține sfaturi de încredere, direct
                online.
              </div>
              <div className="features">
                <div className="left">
                  <Link className="cursor-pointer" to="/medici" key="medici" reloadDocument>
                    <img src={seeDoctors} style={{ width: '100%' }} alt="vezi medici" />
                  </Link>
                  <img src={medicalFile} style={{ width: '100%' }} alt="dosar medical" />
                </div>
                <div className="right">
                  <img
                    src={onlineConsultation}
                    style={{ width: '100%' }}
                    alt="consultatie online"
                  />
                  <img
                    src={recomandareRetata}
                    style={{ width: '100%' }}
                    alt="recomandare si reteta"
                  />
                </div>
              </div>
              <div className="create-account">
                <Link
                  className="cursor-pointer"
                  to="/patient/register"
                  key="patient-register"
                  reloadDocument
                >
                  <span className="orange-button-new" onClick={() => {}}>
                    Creaza-ti un cont acum
                  </span>
                </Link>
              </div>
            </div>
          </section>

          {/* How It Works */}
          <section className="how-it-works">
            <div className="how-it-works-container">
              <div className="title">Cum functioneaza?</div>
              <div className="how-it-works-content">
                <div className="how-it-works-card">
                  <div className="hiw-counter counter-1">1</div>
                  <div className="card-content">
                    <img
                      src={hiw1}
                      alt="star"
                      style={{
                        width: '100%',
                        marginBottom: '12px',
                      }}
                    />
                    <div className="title">Îți creezi un cont Ringdoc</div>
                    <div className="description">
                      Poți adăuga profiluri pentru copii sau alți membri ai familiei și ai acces la
                      dosarele și istoricul medical.
                    </div>
                  </div>
                  <Link
                    className="cursor-pointer"
                    to="/patient/register"
                    key="patient-register"
                    reloadDocument
                  >
                    <div className="card-button">Creaza cont</div>
                  </Link>
                </div>
                <div className="how-it-works-card">
                  <div className="hiw-counter counter-2">2</div>
                  <div className="card-content">
                    <img
                      src={hiw2}
                      alt="star"
                      style={{
                        width: '100%',
                        marginBottom: '12px',
                      }}
                    />
                    <div className="title">Alegi un doctor și tipul de consultație</div>
                    <div className="description">
                      Poți discuta imediat sau programa pentru o dată la alegerea ta. Optează pentru
                      o consultație detaliată sau un control rapid.
                    </div>
                  </div>
                  <Link
                    className="cursor-pointer"
                    to="/patient/register"
                    key="patient-register"
                    reloadDocument
                  >
                    <div className="card-button">
                      <img
                        src={magnifier}
                        alt="doctor not found"
                        style={{
                          width: '18px',
                          height: '18px',
                          marginRight: '8px',
                        }}
                      />
                      Vezi medici
                    </div>
                  </Link>
                </div>
                <div className="how-it-works-card">
                  <div className="hiw-counter counter-3">3</div>
                  <div className="card-content">
                    <img
                      src={howItWorksImg3}
                      alt="star"
                      style={{
                        width: '100%',
                        height: '160px',
                        marginBottom: '12px',
                      }}
                    />
                    <div className="title">Plătești și începi consultația online</div>
                    <div className="description">
                      La final, vei primi recomandările medicului și rețeta medicală.
                    </div>
                  </div>
                </div>
                <div className="how-it-works-card">
                  <div className="card-content">
                    <img
                      src={hiw4}
                      alt="star"
                      style={{
                        width: '100%',
                        marginBottom: '12px',
                      }}
                    />
                    <div className="title">
                      Pentru consultații direct pe telefon, instalează aplicația Ringdoc.
                    </div>
                    <div className="description">
                      Disponibilă gratuit în Google Play și App Store.
                    </div>
                  </div>
                  <div className="card-button store-buttons">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
                      rel="noreferrer"
                    >
                      <div className="store-button">
                        <img
                          src={googlePlayGetItOn}
                          alt="star"
                          style={{
                            width: '100%',
                          }}
                        />
                      </div>
                    </a>

                    <a target="_blank" href={patientAppStoreLink} rel="noreferrer">
                      <div className="store-button">
                        <img
                          src={downloadOnTheAppStore}
                          alt="star"
                          style={{
                            width: '100%',
                          }}
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Reviews */}
          <PublicReviewsSlider />

          {/* Specializations */}
          <section className="specializations">
            <div className="specializations-container">
              <div className="title">Specialitati medicale</div>
              <div className="specializations-content">
                <div className="specializations-list">
                  {specializations?.data?.map((specialization) => {
                    return (
                      <Link
                        className="cursor-pointer"
                        to={`/medici/${specialization.name.toLowerCase()}`}
                        key="doctors"
                        reloadDocument
                      >
                        <div className="specialization-list-item" key={specialization.id}>
                          <img
                            src={specialization?.media?.url}
                            style={{
                              width: '40px',
                              height: '40px',
                              marginRight: '12px',
                            }}
                            alt="specialization.name"
                          />
                          <div className="name" title={specialization.name}>
                            {specialization.name}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
                <div className="specialization-see-more">
                  <Link className="cursor-pointer" to="/medici" key="doctors" reloadDocument>
                    <div className="specialization-see-more-btn">Vezi toate</div>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* 3 ways to make a call */}
          <section className="ways-to-make-a-call">
            <div className="ways-to-make-a-call-container">
              <div className="title">{t('homepage.patient.demo.title')}</div>
              <div className="ways-to-make-a-call-container-content">
                <div className="accordion-container">
                  <Accordion
                    defaultActiveKey="0"
                    onSelect={(e) => {
                      if (e !== null) {
                        setActiveDemoAccordionSlideIndex(e);
                      }
                    }}
                  >
                    <Accordion.Item eventKey="0" id="acc0">
                      <Accordion.Header>
                        <div className="circle one">
                          <span>1</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step1.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step1.description')}
                        {isMobile && (
                          <div className="demo-image-wrapper">
                            <LazyImage
                              width={457}
                              height={632}
                              src={demoAccordionMobile1}
                              className="demo-img"
                              alt="Ringdoc"
                            />
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" id="acc1">
                      <Accordion.Header>
                        <div className="circle two">
                          <span>2</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step2.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step2.description')}
                        {isMobile && (
                          <div className="demo-image-wrapper">
                            <LazyImage
                              width={457}
                              height={632}
                              src={demoAccordionMobile2}
                              className="demo-img"
                              alt="Ringdoc"
                            />
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" id="acc2">
                      <Accordion.Header>
                        <div className="circle three">
                          <span>3</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step3.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step3.description')}
                        {isMobile && (
                          <div className="demo-image-wrapper">
                            <LazyImage
                              width={457}
                              height={550}
                              src={demoAccordionMobile3}
                              className="demo-img"
                              alt="Ringdoc"
                            />
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="accordion-image-container">
                  <img
                    src={demoImages.demoAccordionCollapsible0}
                    alt="Ringdoc"
                    hidden={activeDemoAccordionSlideIndex !== '0'}
                  />
                  <img
                    src={demoImages.demoAccordionCollapsible1}
                    alt="Ringdoc"
                    hidden={activeDemoAccordionSlideIndex !== '1'}
                  />
                  <img
                    src={demoImages.demoAccordionCollapsible2}
                    alt="Ringdoc"
                    hidden={activeDemoAccordionSlideIndex !== '2'}
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Are You Doctor */}
          <section className="are-you-doctor">
            <div className="are-you-doctor-container">
              <div className="title">
                Esti medic?
                <br />
                Mai multi pacienti, mai multa flexibilitate
              </div>
              <div className="content">
                <div className="content-info">
                  Accesează pacienți noi printr-o platformă digitală intuitivă. Oferă consultații
                  online flexibil, în funcție de programul tău, de oriunde.
                </div>
                <div className="content-btn">
                  <span className="green-button-new" onClick={() => {}}>
                    <span>Descopera Ringdoc pentru Medici</span>
                  </span>
                </div>
              </div>
              <div className="features">
                <img src={doctorApp} alt="Doctr App" />
              </div>
            </div>
          </section>

          {/* Newsletter */}
          <section className="newsletter">
            <div className="newsletter-container">
              <div className="newsletter-info">
                <div className="newsletter-info-icon">
                  <img
                    src={mail}
                    alt="a"
                    style={{
                      width: '38px',
                      height: '30px',
                    }}
                  />
                </div>
                <div className="newsletter-info-content">
                  <div className="title">Abonează-te la newsletter</div>
                  <div className="content">
                    Abonează-te la newsletter-ul Ringdoc și primește sfaturi utile pentru sănătatea
                    ta, plus cele mai noi informații din medicină
                  </div>
                </div>
              </div>
              <div className="newsletter-form">
                <MailchimpSubscribe
                  url="https://alodoc.us1.list-manage.com/subscribe/post?u=6ff6095761fe5ec7125b6ce6c&amp;id=2c83ad6e6f&amp;f_id=0036fee5f0"
                  render={({ subscribe, status, message }) => (
                    <CustomNewsletterForm
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                />
              </div>
            </div>
          </section>
        </div>
      )}

      {isMobile && (
        <div className="is-mobile">
          {/* MAIN HERO */}
          <section className="main-hero-1">
            <div className="main-hero-1-main">
              <div className="main-hero-1-section-like-menu">
                <div className="main-hero-1-container-menu">
                  {/* {page === 'homepage' && <PublicHeaderMenu />} */}
                </div>
              </div>
              <div className="main-hero-1-section">
                <div className="main-hero-1-container">
                  <div className="main-hero-1-container-content">
                    <div className="sessions">
                      <strong>+7000</strong> sesiuni finalizate
                    </div>
                    <div className="content-h1">Un medic bun, mereu aproape.</div>
                    <div className="content-text">
                      Conectează-te rapid cu medici de încredere pentru consultații online. Primești
                      diagnostic, recomandări personalizate și rețetă medicală.
                    </div>
                  </div>
                </div>
                <div className="main-img">
                  <div className="content-btn">
                    <span className="orange-button-new" onClick={() => {}}>
                      Descarca aplicatia
                    </span>
                  </div>
                  <img
                    src={backgroundMobile}
                    alt="Ringdoc"
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="main-hero-1-section-like-store">
              <div className="main-hero-1-section-like-store-container">
                <div className="main-hero-1-section-like-store-info">
                  <div className="title">Acces rapid, de oriunde.</div>
                  <div className="content">
                    Îngrijire medicală la îndemână, cu o experiență optimizată pentru mobil.
                  </div>
                </div>
                <div className="main-hero-1-container-store-btns">
                  <div className="store">
                    <div className="app-rating">
                      <div className="app-rating-star">
                        <img
                          src={star}
                          alt="star"
                          style={{
                            width: '28px',
                            height: '28px',
                            marginRight: '8px',
                          }}
                        />
                      </div>
                      <div className="app-rating-rating">
                        <span className="rating-value">4.6</span>/5
                      </div>
                    </div>
                    <div className="btn">
                      <img
                        src={appleStoreIcon}
                        alt="doctor not found"
                        style={{
                          width: '22px',
                          height: '27px',
                          marginRight: '9px',
                        }}
                      />
                      <div className="btn-text">
                        <div className="btn-text-info">Disponibil în</div>
                        <div className="btn-text-store">App Store</div>
                      </div>
                    </div>
                  </div>
                  <div className="store store-google">
                    <div className="app-rating">
                      <div className="app-rating-star">
                        <img
                          src={star}
                          alt="star"
                          style={{
                            width: '28px',
                            height: '28px',
                            marginRight: '8px',
                          }}
                        />
                      </div>
                      <div className="app-rating-rating">
                        <span className="rating-value">4.1</span>/5
                      </div>
                    </div>
                    <div className="btn">
                      <img
                        src={googlePlayStoreIcon}
                        alt="Google Play Store Icon"
                        style={{
                          width: '24px',
                          height: '27px',
                          marginRight: '9px',
                        }}
                      />
                      <div className="btn-text">
                        <div className="btn-text-info">Disponibil în</div>
                        <div className="btn-text-store">Google Play</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ai">
            <div className="ai-container">
              <div className="ai-info">
                <div className="pre-header">
                  <img src={diagnosticAiIcon} alt="diagnosticA" />
                  <div className="pre-header-info">Diagnostic AI</div>
                </div>
                <div className="ai-header">Află rapid ce se întâmplă cu tine</div>
                <div className="ai-content">
                  Discută ca și cum ai face-o cu un medic, alături de un asistent AI specializat și
                  verificat de medici. Descoperă posibile diagnostice, interpretări ale analizelor
                  sau informații esențiale despre sănătatea ta.*
                </div>
                <div className="ai-start-conversation">
                  <div className="white-button-new" onClick={() => {}}>
                    <div className="white-button-new-text">Începe conversația</div>
                    <img src={rightCustomArrow} alt="diagnosticAI" />
                  </div>
                </div>
              </div>
              <div className="ai-dr-img">
                <img src={aiDoctorMobile} alt="diagnosticAI" />
              </div>
              <div className="note">
                *Asistentul AI nu înlocuiește un consult medical și nu poate oferi un diagnostic
                oficial. Răspunsurile sale se bazează pe date statistice și trebuie luate doar ca
                recomandări orientative. Pentru un diagnostic cert și îngrijire adecvată, consultă
                un medic specialist.
              </div>
            </div>
          </section>

          {/* Reputation Hospitals */}
          <PublicReputationSlider />

          {/* Main information */}
          <section className="main-informtion">
            <div className="main-informtion-container">
              <div className="title">Când ai nevoie de un medic, indiferent unde te afli.</div>
              <div className="content">
                Fie că vrei să discuți cu doctorul tău, cauți un specialist pentru un consult rapid
                sau ai nevoie de o a doua opinie medicală, poți obține sfaturi de încredere, direct
                online.
              </div>
              <div className="features">
                <div className="left">
                  <img src={seeDoctors} style={{ width: '100%' }} alt="vezi medici" />
                  <img
                    src={onlineConsultation}
                    style={{ width: '100%' }}
                    alt="consultatie online"
                  />
                  <img src={medicalFile} style={{ width: '100%' }} alt="dosar medical" />
                  <img
                    src={recomandareRetata}
                    style={{ width: '100%' }}
                    alt="recomandare si reteta"
                  />
                </div>
              </div>
              <div className="create-account">
                <span className="orange-button-new" onClick={() => {}}>
                  Creaza-ti un cont acum
                </span>
              </div>
            </div>
          </section>

          {/* How It Works */}
          <section className="how-it-works">
            <div className="how-it-works-container">
              <div className="title">Cum functioneaza?</div>
            </div>
          </section>

          {/* Reviews */}
          <PublicReviewsSlider />

          {/* Specializations */}
          <section className="specializations">
            <div className="specializations-container">
              <div className="title">Specialitati medicale</div>
              <div className="specializations-content">
                <div className="specializations-list">
                  {specializations?.data?.map((specialization) => {
                    return (
                      <div className="specialization-list-item" key={specialization.id}>
                        <img
                          src={specialization?.media?.url}
                          style={{
                            width: '40px',
                            height: '40px',
                            marginRight: '12px',
                          }}
                          alt="specialization.name"
                        />
                        <div className="name" title={specialization.name}>
                          {specialization.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="specialization-see-more">
                  <div className="specialization-see-more-btn">Vezi toate</div>
                </div>
              </div>
            </div>
          </section>

          {/* 3 ways to make a call */}
          <section className="ways-to-make-a-call">
            <div className="ways-to-make-a-call-container">
              <div className="title">{t('homepage.patient.demo.title')}</div>
              <div className="ways-to-make-a-call-container-content">
                <div className="accordion-container">
                  <Accordion
                    defaultActiveKey="0"
                    onSelect={(e) => {
                      if (e !== null) {
                        setActiveDemoAccordionSlideIndex(e);
                      }
                    }}
                  >
                    <Accordion.Item eventKey="0" id="acc0">
                      <Accordion.Header>
                        <div className="circle one">
                          <span>1</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step1.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step1.description')}

                        <div className="demo-image-wrapper">
                          <img
                            src={demoImages.demoAccordionCollapsible0}
                            className="demo-img"
                            alt="Ringdoc"
                            style={{
                              width: '100%',
                            }}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" id="acc1">
                      <Accordion.Header>
                        <div className="circle two">
                          <span>2</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step2.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step2.description')}
                        <div className="demo-image-wrapper">
                          <img
                            style={{
                              width: '100%',
                            }}
                            src={demoImages.demoAccordionCollapsible1}
                            className="demo-img"
                            alt="Ringdoc"
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" id="acc2">
                      <Accordion.Header>
                        <div className="circle three">
                          <span>3</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step3.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step3.description')}
                        <div className="demo-image-wrapper">
                          <img
                            style={{
                              width: '100%',
                            }}
                            src={demoImages.demoAccordionCollapsible2}
                            className="demo-img"
                            alt="Ringdoc"
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>

          {/* Are You Doctor */}
          <section className="are-you-doctor">
            <div className="are-you-doctor-container">
              <div className="title">
                Esti medic?
                <br />
                Mai multi pacienti, mai multa flexibilitate
              </div>
              <div className="content">
                <div className="content-info">
                  Accesează pacienți noi printr-o platformă digitală intuitivă. Oferă consultații
                  online flexibil, în funcție de programul tău, de oriunde.
                </div>
                <div className="content-btn">
                  <span className="green-button-new" onClick={() => {}}>
                    <span>Descopera Ringdoc pentru Medici</span>
                  </span>
                </div>
              </div>
              <div className="features">
                <img src={doctorApp} alt="Doctr App" />
              </div>
            </div>
          </section>

          {/* Newsletter */}
          <section className="newsletter">
            <div className="newsletter-container">
              <div className="newsletter-info">
                <div className="newsletter-info-icon">
                  <img
                    src={mail}
                    alt="a"
                    style={{
                      width: '38px',
                      height: '30px',
                    }}
                  />
                </div>
                <div className="newsletter-info-content">
                  <div className="title">Abonează-te la newsletter</div>
                  <div className="content">
                    Abonează-te la newsletter-ul Ringdoc și primește sfaturi utile pentru sănătatea
                    ta, plus cele mai noi informații din medicină
                  </div>
                </div>
              </div>
              <div className="newsletter-form">
                <MailchimpSubscribe
                  url="https://alodoc.us1.list-manage.com/subscribe/post?u=6ff6095761fe5ec7125b6ce6c&amp;id=2c83ad6e6f&amp;f_id=0036fee5f0"
                  render={({ subscribe, status, message }) => (
                    <CustomNewsletterForm
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                />
              </div>
            </div>
          </section>
        </div>
      )}

      {page !== 'isLanding' && <PublicFooterSection />}

      <Modal
        show={showModalVideo}
        onHide={modalVideoClose}
        animation
        centered
        className="modal-video"
      >
        <Modal.Header closeButton closeLabel={t('close')} />

        <Modal.Body>
          <div className="iframe-responsive">
            <iframe
              src="https://www.youtube.com/embed/EfG0jJVO4DQ?si=6arPcltAPFfqHg6I"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatientMainScreenComponent;

import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Rating } from 'react-simple-star-rating';
import { shallowEqual, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { getAllReviewsState } from '../redux/src/StatesGetter';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const PublicReviewsSlider = () => {
  const reviews = useSelector(getAllReviewsState, shallowEqual);

  return (
    <>
      {reviews.length > 0 && (
        <section className="review">
          <div className="review-container">
            <div className="review-container-title">Ce spun cei care au folosit Ringdoc?</div>
            <Swiper
              // install Swiper modules
              modules={[Navigation]}
              spaceBetween={16}
              slidesPerView={isMobile ? 1.2 : 3.2}
              navigation={{
                nextEl: '.custom-reviews-next',
                prevEl: '.custom-reviews-prev',
              }}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => console.log('slide change')}
              className="reviews-swiper"
            >
              {reviews.map((review) => {
                return (
                  <SwiperSlide>
                    <div className="review-carousel-item">
                      <div className="review-data">
                        <div className="header">
                          <div className="patient-info">
                            <div className="patient-name">{`${review.originator ?? '-'} `}</div>
                            <div className="patient-location">
                              <span>{`${review.city ?? '-'}`}</span>
                            </div>
                          </div>
                          <div className="rating-stars">
                            <Rating
                              fillColor="#FFBF00"
                              size={28}
                              readonly
                              initialValue={review.rating ?? 0}
                              allowFraction
                            />
                          </div>
                        </div>
                        <div className="review-message">{review.remarks ?? '-'}</div>
                      </div>

                      <div className="review-doctor">
                        <div className="review-doctor-info">
                          <div className="dr-name">{review.doctorFullName ?? '-'}</div>
                          <div className="dr-specialization">{review.specialization ?? '-'}</div>
                        </div>
                        <div className="review-doctor-img">
                          {!review?.doctorImage && (
                            <div className="avatar" style={{ backgroundColor: '#E5E7E8' }}>
                              <div className="image-placeholder">{`${review?.doctorFirstName?.charAt(
                                0,
                              )}${review?.doctorLastName?.charAt(0)}`}</div>
                            </div>
                          )}
                          {review?.doctorImage && (
                            <div className="avatar">
                              <img
                                src={review?.doctorImage}
                                width={48}
                                height={48}
                                alt="doc_image"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="custom-nav">
              {/* eslint-disable-next-line react/button-has-type */}
              <button className="custom-reviews-prev">
                <div className="icon colored" />
              </button>
              {/* eslint-disable-next-line react/button-has-type */}
              <button className="custom-reviews-next">
                <div className="icon colored" />
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PublicReviewsSlider;

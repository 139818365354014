import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getAnalytics, logEvent } from '@firebase/analytics';
import camera from 'shared/assets/icons/camera.svg';
import busy from 'shared/assets/icons/busy.svg';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';

const DoctorCardCallButton = (props) => {
  const { t } = useTranslation();
  const {
    status,
    onClickEvent,
    upcomingConsultation,
    listItemSchedule,
    centerTextButton,
    listItemType,
  } = props;

  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const availableTextTo = (item) => {
    if (item) {
      const day = dayjs(item).format('DD');
      if (day === dayjs().format('DD') || day === dayjs().add(1, 'day').format('DD')) {
        // return t('medicalApp.availability');
        return t('medicalApp.availabilityFrom');
      }
    }

    return t('medicalApp.availabilityFrom');
  };

  const callNowAction = () => {
    const analytics = getAnalytics();
    logEvent(analytics, 'call_now_initialized_web', {});

    onClickEvent();
  };

  const callScheduleAction = () => {
    const analytics = getAnalytics();
    logEvent(analytics, 'programmed_initialized_web', {});

    onClickEvent();
  };

  const btnSwitch = (param) => {
    switch (param) {
      case 'online':
        return (
          <div>
            <span onClick={callNowAction} className="green-button-new">
              <img src={camera} alt="camera" className="camera" />
              <div className="call-now-btn-text">{t('callNow')}</div>
            </span>
            <div className="appointment-separator">sau</div>
          </div>
        );
      case 'busy':
        return (
          <span className="yellow-button-new">
            <img src={busy} alt="camera" className="busy" />
            <div className="call-now-btn-text">{t('busy')}</div>
          </span>
        );
      default:
        return <div />;
    }
  };

  return btnSwitch(status);
};

export default DoctorCardCallButton;

import i18next from 'i18next';

export const resolveDoctorSpecializations = (activeDoctorSpecializations) => {
  return Object.values(activeDoctorSpecializations).map((activeDoctorSpecialization) => {
    const activeDoctorServices = Object.values(
      // @ts-ignore
      activeDoctorSpecialization.active_doctor_services,
    ).map((activeService) => {
      return activeService;
    });
    return {
      // @ts-ignore
      id: activeDoctorSpecialization.specialization.id,
      // @ts-ignore
      isActive: activeDoctorSpecialization.specialization.is_active,
      // @ts-ignore
      isAvailable: activeDoctorSpecialization.specialization.is_available,
      name:
        // @ts-ignore
        activeDoctorSpecialization.specialization.translations.find(
          (row) => row.locale === i18next.language,
        )?.name ?? '',
      // @ts-ignore
      doctorServices: activeDoctorServices,
    };
  });
};

// export const resolveActiveDoctorSpecializations = (doctor) => {
//   return Object.values(doctor.active_doctor_specializations).map((specRow) => {
//     // @ts-ignore
//     return specRow.specialization.translations.find((row) => row.locale === i18next.language)?.name;
//   });
// };

export const resolveDoctorListDoctorSpecializations = (specialization) => {
  // const filteredSpecializations = specializations?.filter((row) => {
  //   return row.is_visible && row.is_validated;
  // });

  // console.log('aaafilteredSpecializations', filteredSpecializations);

  // const doctorSpecializations = filteredSpecializations.map((specialization) => {
  //   return {
  //     id: specialization?.id,
  //     price: specialization.price_as_string,
  //     priceAsString: specialization.price_as_string,
  //     parentId: specialization.specialization?.id,
  //     name:
  //       specialization.specialization.translations.find((row) => row.locale === i18next.language)
  //         ?.name ?? '',
  //     description:
  //       specialization.specialization.translations.find((row) => row.locale === i18next.language)
  //         ?.description ?? '',
  //     certificationsPortfolio: specialization.certifications_portfolio,
  //     isValidated: specialization.is_validated,
  //     isAvailable: specialization.is_available,
  //     duration: specialization.duration,
  //     // activeDoctorServices: specialization.active_doctor_services,
  //     doctorServices: specialization.doctor_services,
  //   };
  // });

  return [];
};

export const resolveUserLanguages = (userLanguages) => {
  return userLanguages.map((language) => {
    return {
      locale: language.current_locale,
    };
  });
};

import { translatedDate, translatedFullDate } from 'shared/modules/DateTimeUtils';
import {
  resolveDoctorSpecializations,
  resolveUserLanguages,
} from 'shared/modules/PatientDataFormatter';
import { resolveAvailability } from 'shared/modules/DoctorDataFormatter';
import i18next from 'i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import Utils from '../../modules/Utils';

const doctorProfileSelector = (profile) => {
  const { doctor, timetable, reviews } = profile;
  const resolveTimeTable = () => {
    return Object.entries(timetable).map((row) => {
      return {
        date: row[0],
        intervals: row[1],
      };
    });
  };

  const resolveReviews = () => {
    return reviews.map((row) => {
      return {
        id: row?.id,
        rating: row.rating,
        remarks: row.remarks,
        createdAt: translatedDate(row.created_at),
        originator: row.anonymized_name,
      };
    });
  };

  const resolveMeta = () => {
    if (!doctor.translations) {
      return {};
    }
    const metaValues = {};

    doctor.translations.forEach((row) => {
      const metaRowValues = {
        metaTitle: row.meta_title,
        metaDescription: row.meta_description,
      };

      _.set(metaValues, row.locale, metaRowValues);
    });

    return metaValues;
  };

  return {
    id: doctor?.id,
    email: doctor.email,
    // locale: doctor.locale,
    // accountStatus: doctor.account_status,
    firstName: doctor.first_name,
    lastName: doctor.last_name,
    // name: doctor.full_name_with_title,
    fullNameWithTitle: doctor.full_name_with_title,
    doctorDescription: doctor.doctor_description,
    imgUrl: doctor.picture_media?.public_url ?? null,
    // numericPersonalCode: doctor.numeric_personal_code,
    // gender: Utils.solveGender(doctor?.gender),
    // birthDate: doctor.birth_date,
    // phoneNumberCountry: doctor.phone_number_country?.calling_code,
    // phoneNumber: doctor.phone_number,
    pictureMedia: doctor.picture_media?.public_url ?? null,
    userLanguages: resolveUserLanguages(doctor?.user_languages ?? []),
    stripeConnectedAccount: doctor.stripe_connected_account,
    createdAt: doctor.created_at,
    updatedAt: doctor.updated_at,
    deletedAt: doctor.deleted_at,
    managedBy: doctor.managed_by,
    managedUsers: doctor.managed_users,
    weight: doctor.weight,
    height: doctor.height,
    bloodSystem: doctor.blood_system,
    isSmoker: doctor.is_smoker,
    isAlcoholConsumer: doctor.is_alcohol_consumer,
    knownAllergies: doctor.known_allergies,
    pastMedicalConditions: doctor.past_medical_conditions,
    currentMedicalConditions: doctor.current_medical_conditions,
    patientKinshipDiseases: doctor.patient_kinship_diseases,
    availability: resolveAvailability(
      doctor.is_available_for_call_now,
      doctor.is_busy,
      doctor.is_fake_busy,
    ),
    // organization: {
    //   id: doctor.organization?.id,
    //   name: doctor.organization?.name,
    //   clinicName: doctor.organization?.clinic_name,
    //   uniqueIdentificationNumber: doctor.organization?.unique_identification_number,
    //   commercialRegistrationNumber: doctor.organization?.commercial_registration_number,
    //   address: {
    //     line1: doctor.organization?.address?.line1,
    //     line2: doctor.organization?.address?.line2,
    //     city: doctor.organization?.address?.city,
    //     county: doctor.organization?.address?.county,
    //     country:
    //       doctor.organization?.address?.country?.translations?.find(
    //         (row) => row.locale === i18next.language,
    //       )?.name ?? '',
    //   },
    //   bankName: doctor.organization?.bank_name,
    //   iban: doctor.organization?.iban,
    //   bankCurrency: doctor.organization?.currency.code,
    //   createdAt: doctor.organization?.created_at,
    //   updatedAt: doctor.organization?.updated_at,
    //   deletedAt: doctor.organization?.deleted_at,
    // },
    // address: {
    //   line1: doctor.address?.line1,
    //   line2: doctor.address?.line2,
    //   city: doctor.address?.city,
    //   county: doctor.address?.county,
    //   country:
    //     doctor.address?.country?.translations && Array.isArray(doctor.address.country.translations)
    //       ? doctor.address.country.translations.find((row) => row.locale === i18next.language)?.name
    //       : '',
    // },
    // identityCardMedia: doctor?.identity_card_media,
    // additionalDocumentMedia: doctor.additional_document_media,
    // cvMediaUrl: doctor.cv_media?.public_url,
    // cvMedia: {
    //   id: doctor.cv_media?.id ?? 0,
    //   fileName: doctor.cv_media?.original_file_name ?? '',
    //   type: doctor.cv_media?.original_file_name?.split('/')?.pop() ?? '',
    //   updatedAt: doctor?.cv_media?.updated_at
    //     ? dayjs(doctor.cv_media.updated_at).format('YYYY-MM-DD')
    //     : '',
    // },
    averageRating: doctor.average_rating,
    countRating: doctor.count_rating,
    // isAvailableForCallNow: doctor.is_available_for_call_now,
    // isAvailableForEmergency: doctor.is_available_for_emergency,
    hasSchedule: doctor?.has_schedule,
    doctorSpecializations: resolveDoctorSpecializations(doctor.active_doctor_specializations),
    timeTable: resolveTimeTable(),
    reviews: resolveReviews(),
    // rawFirstAvailableTime: doctor.first_available_time,
    // firstAvailableTime: doctor.first_available_time
    //   ? translatedFullDate(doctor.first_available_time, true, true)
    //   : null,
    meta: resolveMeta(),
  };
};

export default doctorProfileSelector;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { getEnv, authFooter, isDoctor, consultationFlowFooter } from 'shared/services/EnvService';
import { useTranslation } from 'react-i18next';
import Colors from 'shared/themes/Colors';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ringdocOrangeWhiteLogo from 'shared/assets/images/ringdoc-orange-white.png';
import CustomIcon from './CustomIcon';
import { requestLogout } from '../redux/actions/AuthActions';
import StorageService from '../services/StorageService';
import { settingsState } from '../redux/src/StatesGetter';
import { scrollToTop } from '../modules/WindowUtils';

const FooterSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector(settingsState, shallowEqual);

  const onChangePlatform = (platform: string) => {
    dispatch(requestLogout({}));
    localStorage.setItem('CHANGE_PLATFORM', platform);
    StorageService.setData('IS_CHANGE_PLATFORM', true);

    setTimeout(() => {
      if (platform === 'doctor') {
        navigate('/doctor/landing');
      } else {
        navigate('/');
      }
    }, 500);
  };

  return (
    <>
      {!isMobile && (
        <section className="footer">
          <div className="footer-container">
            <div className="footer-column">
              <div className="footer-logo">
                <img src={ringdocOrangeWhiteLogo} alt="Ringdoc" />
              </div>
              <div className="social-media">
                <a
                  target="_blank"
                  href="https://www.facebook.com/ringdocapp"
                  rel="noreferrer"
                  className="social-media-link"
                >
                  <CustomIcon
                    className="custom-icon"
                    color={authFooter() ? Colors.white : Colors.mediumGrey}
                    size="20"
                    icon="Fb"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/ringdocapp"
                  rel="noreferrer"
                  className="social-media-link"
                >
                  <i
                    className="instagram-icon"
                    style={{
                      width: '21px',
                      height: '21px',
                    }}
                  />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@ringdocapp"
                  rel="noreferrer"
                  className="social-media-link"
                >
                  <CustomIcon
                    className="custom-icon"
                    color={authFooter() ? Colors.white : Colors.mediumGrey}
                    size="20"
                    icon="Play"
                  />
                </a>
              </div>
              <div className="copyright">
                {`Copyright © ${dayjs().format('YYYY')} ${getEnv('APP_NAME')}`}
                <br />
                {`${t('support.allRightsReserved')}`}.{' '}
                {`${t('support.version')} ${getEnv('VERSION')}`}
              </div>
            </div>
            <div className="footer-column">
              <div className="title">{t('footer.patients')}</div>
              <Link to="/patient/how-it-works">{t('footer.howItWorks')}</Link>
              <HashLink smooth to="/patient/how-it-works#faq">
                {t('support.faq')}
              </HashLink>
              <Link to="/patient/login">{t('patientApp.login')}</Link>
              <Link to="/patient/register">{t('patientApp.createAccount')}</Link>
            </div>
            <div className="footer-column">
              <div className="title">{t('footer.doctors')}</div>
              <Link
                to="/doctor/how-it-works"
                onClick={(e) => {
                  scrollToTop();
                }}
              >
                {t('footer.howItWorks')}
              </Link>
              <HashLink smooth to="/doctor/how-it-works#faq">
                {t('support.faq')}
              </HashLink>
              <Link to="/doctor/login">{t('patientApp.login')}</Link>
              <Link to="/doctor/register">{t('patientApp.createAccount')}</Link>
            </div>
            <div className="footer-column">
              <div className="title">Descopera</div>
              <Link to={`${isDoctor(true) ? '/doctor/about-us' : '/patient/about-us'}`}>
                {t('support.aboutUsRingDoc')}
              </Link>
              <Link to={`${isDoctor(true) ? '/doctor/contact' : '/patient/contact'}`}>
                {t('contact')}
              </Link>
              <Link to={`${isDoctor(true) ? '/doctor/partners' : '/patient/partners'}`}>
                {t('partners.partners')}
              </Link>
              <a href="https://blog.ringdoc.ro">{t('blog')}</a>
              <Link to="/support/terms-and-conditions">{t('support.terms2')}</Link>
              <Link to="/support/privacy-policy">{t('support.policy')}</Link>
              <Link to="/support/cookie-policy">{t('support.cookie-policy')}</Link>
              {settings?.campaignTermsAndConditions && (
                <Link to="/support/campaign-rules">{t('support.campaign-rules')}</Link>
              )}
            </div>
          </div>
        </section>
      )}
      {isMobile && (
        <div className="is-mobile">
          <section className="footer">
            <div className="footer-container">
              <div className="footer-column">
                <div className="footer-logo">
                  <img src={ringdocOrangeWhiteLogo} alt="Ringdoc" />
                </div>
                <div className="social-media">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/ringdocapp"
                    rel="noreferrer"
                    className="social-media-link"
                  >
                    <CustomIcon
                      className="custom-icon"
                      color={authFooter() ? Colors.white : Colors.mediumGrey}
                      size="20"
                      icon="Fb"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/ringdocapp"
                    rel="noreferrer"
                    className="social-media-link"
                  >
                    <i
                      className="instagram-icon"
                      style={{
                        width: '21px',
                        height: '21px',
                      }}
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@ringdocapp"
                    rel="noreferrer"
                    className="social-media-link"
                  >
                    <CustomIcon
                      className="custom-icon"
                      color={authFooter() ? Colors.white : Colors.mediumGrey}
                      size="20"
                      icon="Play"
                    />
                  </a>
                </div>
                <div className="copyright">
                  {`Copyright © ${dayjs().format('YYYY')} ${getEnv('APP_NAME')}`}
                  <br />
                  {`${t('support.allRightsReserved')}`}.{' '}
                  {`${t('support.version')} ${getEnv('VERSION')}`}
                </div>
              </div>
              <div className="footer-column">
                <div className="title">{t('footer.patients')}</div>
                <Link to="/patient/how-it-works">{t('footer.howItWorks')}</Link>
                <HashLink smooth to="/patient/how-it-works#faq">
                  {t('support.faq')}
                </HashLink>
                <Link to="/patient/login">{t('patientApp.login')}</Link>
                <Link to="/patient/register">{t('patientApp.createAccount')}</Link>
              </div>
              <div className="footer-column">
                <div className="title">{t('footer.doctors')}</div>
                <Link
                  to="/doctor/how-it-works"
                  onClick={(e) => {
                    scrollToTop();
                  }}
                >
                  {t('footer.howItWorks')}
                </Link>
                <HashLink smooth to="/doctor/how-it-works#faq">
                  {t('support.faq')}
                </HashLink>
                <Link to="/doctor/login">{t('patientApp.login')}</Link>
                <Link to="/doctor/register">{t('patientApp.createAccount')}</Link>
              </div>
              <div className="footer-column">
                <div className="title">Descopera</div>
                <Link to={`${isDoctor(true) ? '/doctor/about-us' : '/patient/about-us'}`}>
                  {t('support.aboutUsRingDoc')}
                </Link>
                <Link to={`${isDoctor(true) ? '/doctor/contact' : '/patient/contact'}`}>
                  {t('contact')}
                </Link>
                <Link to={`${isDoctor(true) ? '/doctor/partners' : '/patient/partners'}`}>
                  {t('partners.partners')}
                </Link>
                <a href="https://blog.ringdoc.ro">{t('blog')}</a>
                <Link to="/support/terms-and-conditions">{t('support.terms2')}</Link>
                <Link to="/support/privacy-policy">{t('support.policy')}</Link>
                <Link to="/support/cookie-policy">{t('support.cookie-policy')}</Link>
                {settings?.campaignTermsAndConditions && (
                  <Link to="/support/campaign-rules">{t('support.campaign-rules')}</Link>
                )}
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default FooterSection;

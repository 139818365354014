import React from 'react';
import { createRoot } from 'react-dom/client';
import 'normalize.css';
import 'shared/styles/common.css';
import 'shared/styles/pagination.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shared/styles/index.css';
import 'rc-slider/assets/index.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from 'shared/redux/ReduxStore';
import connectionOptions from 'shared/twilio/ConnectionOptions';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import 'shared/i18n/I18n';
import reportWebVitals from 'reportWebVitals';
import App from 'App';

import { AgoraVideoProvider } from './shared/agora/AgoraProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<LoadingSpinner isLoading />} persistor={persistor}>
      <AgoraVideoProvider options={connectionOptions} onError={() => {}}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AgoraVideoProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

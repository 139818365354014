import 'patient/styles/medic-profile.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getDoctorProfileState } from 'shared/redux/src/StatesGetter';
import { fetchDoctorProfileRequest } from 'shared/redux/actions/DoctorProfileActions';
import { useTranslation } from 'react-i18next';

const PatientMedicProfileCV = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const doctor = useSelector(getDoctorProfileState, shallowEqual);

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchDoctorProfileRequest({ id: id.split('-')[id.split('-').length - 1] }));
  }, []);
  const [numPages, setNumPages] = useState<number>();

  console.log('s');
  return (
    <section className="medic-profile">
      <div className="medic-small-box">
        <div
          className="avatar"
          style={
            doctor?.imgUrl
              ? { backgroundImage: `url(${doctor?.imgUrl})` }
              : { backgroundColor: '#E5E7E8' }
          }
        >
          {!doctor?.imgUrl && (
            <div className="image-placeholder">{`${doctor?.firstName?.charAt(
              0,
            )}${doctor?.lastName?.charAt(0)}`}</div>
          )}
        </div>
        <div className="info">
          <div className="name">{doctor.name}</div>
          <div className="rating">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="14"
              icon="Rating_2"
            />
            {t('note')} {doctor.averageRating} ({doctor.countRating} {t('noOfReviews')})
          </div>
        </div>
      </div>
      <div className="curriculum-vitae">
        <div className="subtitle">{t('cv')}</div>
        <div className="cv-preview">
          {doctor?.cvMediaUrl && !doctor?.cvMediaUrl?.endsWith('.pdf') && (
            <img src={doctor?.cvMediaUrl} className="img-fluid" alt="Curriculum Vitae" />
          )}

          {doctor?.cvMediaUrl && doctor?.cvMediaUrl?.endsWith('.pdf') && (
            <object data={doctor?.cvMediaUrl} type="application/pdf" height="700px" width="100%">
              {t('patientApp.drCVNotAvailable')}
            </object>
          )}
        </div>
      </div>
    </section>
  );
};

export default PatientMedicProfileCV;

import participantPlatformSaga from '../saga/ParticipantPlatformSaga';

const participantPlatformPlaceholder = {
  platform: '',
};

const participantPlatformSelector = (participantPlatform) => {
  return {
    platform: participantPlatform.platform,
  };
};

export { participantPlatformSelector, participantPlatformPlaceholder };

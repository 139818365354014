import React from 'react';
import { Link } from 'react-router-dom';
import magnifier from 'shared/assets/icons/Magnifier.svg';
import PublicLanguageNavSelector from '../../patient/components/PublicLanguageNavSelector';

const PublicHeaderMenu = () => {
  return (
    <>
      <div className="public-header-menu">
        <div className="public-header-menu-logo">
          <Link className="logo cursor-pointer" to="/" key="LOGO" reloadDocument>
            <i className="ringdoc-color-logo" />
          </Link>
        </div>
        <div className="public-header-menu-mid">
          <Link className="cursor-pointer" to="/medici" key="medici" reloadDocument>
            <div className="menu-link">
              <img
                src={magnifier}
                alt="doctor not found"
                style={{
                  width: '18px',
                  height: '18px',
                  marginRight: '8px',
                }}
              />
              Medici
            </div>
          </Link>

          <div className="menu-link">Diagnostic AI</div>
          <Link className="cursor-pointer" to="/patient/contact" key="contact" reloadDocument>
            <div className="menu-link">Contact</div>
          </Link>
        </div>
        <div className="public-header-menu-right">
          <div className="public-header-menu-right-container">
            <Link
              className="cursor-pointer"
              to="/doctor/landing"
              key="doctor-landing"
              reloadDocument
            >
              <div className="menu-link i-m-doctor">Sunt medic</div>
            </Link>
            <Link className="cursor-pointer" to="/patient/login" key="patient-login" reloadDocument>
              <div className="menu-link">Autentificare</div>
            </Link>
            <Link
              className="cursor-pointer"
              to="/patient/register"
              key="patient-register"
              reloadDocument
            >
              <div className="menu-link create-account">Creeză cont</div>
            </Link>
          </div>
          <div className="public-header-menu-right-language-container">
            <PublicLanguageNavSelector />
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicHeaderMenu;

import 'patient/styles/doctors.css';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import circleI from 'shared/assets/icons/circle-i.svg';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import i18next, { use } from 'i18next';
import magnifier from 'shared/assets/icons/Magnifier.svg';
import sorting from 'shared/assets/icons/sorting.svg';
import downArrow from 'shared/assets/icons/Down_arrow.svg';
import upArrow from 'shared/assets/icons/Up_arrow.svg';
import dots from 'shared/assets/icons/3dots.svg';
import recyclebin from 'shared/assets/icons/recyclebin.svg';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import PublicHeaderMenu from '../../../shared/components/PublicHeaderMenu';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import FindADoctorCard from '../medicList/FindADoctorCard';
import PatientMedicCard from '../medicList/PatientMedicCard';
import {
  filtersDoctorsState,
  getAuthState,
  getFavouriteDoctorsState,
  getPatientAppointmentState,
  getSpecializationDoctorsState,
  getSpecializationsState,
} from '../../../shared/redux/src/StatesGetter';
import {
  getFilterDoctorsRequest,
  getSpecializationDoctorsRequest,
} from '../../../shared/redux/actions/DoctorsActions';
import {
  addFavouriteDoctorRequest,
  getFavouriteDoctorsRequest,
  removeFavouriteDoctorRequest,
} from '../../../shared/redux/actions/FavouriteDoctorsActions';
import { getSpecializationsRequest } from '../../../shared/redux/actions/SpecializationActions';
import {
  allowNewAppointment,
  requestAppointment,
} from '../../../shared/modules/PatientAppointmentUtils';
import { fetchDoctorProfileRequest } from '../../../shared/redux/actions/DoctorProfileActions';
import { appointmentLocalStepsRequest } from '../../../shared/redux/actions/PatientAppointmentActions';
import GAService from '../../../shared/services/GAService';
import DoctorCard from '../medicList/DoctorCard';
import PublicFooterSection from '../../../shared/components/PublicFooterSection';

type medicFiltersAvailable = {
  specialization: any[];
  organization: any[];
  area: any[];
  specializationSearch?: string;
  doctorNameSearch?: string;
  seoTitle?: string;
  sortingOptions: string;
};

const Doctors = () => {
  const { specialization: specializationParamSelected } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const {
    data: doctors,
    currentPage,
    noOfPages,
  } = useSelector(getSpecializationDoctorsState, shallowEqual);

  const [medicFilters, setMedicFilters] = useState<medicFiltersAvailable>({
    doctorNameSearch: '',
    specialization: [],
    organization: [],
    area: [],
    seoTitle: '',
    sortingOptions: '',
  });
  const { ids: favouriteDoctorsIds } = useSelector(getFavouriteDoctorsState, shallowEqual);
  const specializations = useSelector(getSpecializationsState, shallowEqual);
  const { status: appointmentStatus, stickyStatus } = useSelector(
    getPatientAppointmentState,
    shallowEqual,
  );
  const {
    organization: organizationFilter,
    specialization: specializationFilter,
    area: areaFilter,
  } = useSelector(filtersDoctorsState, shallowEqual);

  const [popEnabled, setPopEnabled] = useState(false);
  const [specializationSearch, setSpecializationSearch] = useState('');
  const [filterSearch, setFilterSearch] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [doctorNameSearch, setDoctorNameSearch] = useState('');
  const [isActive, setActive] = useState(false);
  const [isSortingModal, setIsSortingModal] = useState(false);
  const [isSpecializationModal, setIsSpecializationModal] = useState(false);
  const [specializationFilterValues, setSpecializationFilterValues] = useState<any[]>([]);
  const [isOrganizationModal, setIsOrganizationModal] = useState(false);
  const [organizationFilterValues, setOrganizationFilterValues] = useState<any[]>([]);
  const [isAreaModal, setIsAreaModal] = useState(false);
  const [areaFilterValues, setAreaFilterValues] = useState<any[]>([]);

  let haveActiveDoctors = true;

  // Load More
  const loadMore = () => {
    const { specialization, doctorNameSearch: searchName } = medicFilters;

    dispatch(
      getSpecializationDoctorsRequest({
        specialization,
        searchName,
        // @ts-ignore
        sortingOptions: sortOption,
        page: currentPage,
        nextPage: true,
      }),
    );
  };

  // Schedule
  const onScheduleClicked = (doctor, specializationId, hasControl) => {
    if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
      return;
    }
    dispatch(fetchDoctorProfileRequest({ id: doctor?.id }));

    if (hasControl) {
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
          noOfSteps: 6,
          specializationId,
          type: 'programmed',
          doctor,
          hasControl: true,
        }),
      );
      navigate(`/patient/appointment/choose-service?ds=${doctor.specializationId}`);
    } else {
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
          noOfSteps: 5,
          specializationId,
          type: 'programmed',
          doctor,
          service: 'consultation',
          hasControl: false,
        }),
      );
      navigate('/patient/appointment/set-date');
    }
  };

  // Call now
  const onCallNowClicked = (doctor, specializationId) => {
    if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
      return;
    }
    // const specializationName = doctor.doctorSpecializations
    //   ?.map((specialization) => specialization.name)
    //   .join(', ');

    GAService.event('web_callNow', {
      specializationName: doctor.specializationName,
      specializationId,
    });
    requestAppointment('callNow', {
      forRequest: {
        doctor: `${doctor?.id}`,
        specialization: `${specializationId}`,
      },
      forUpdate: {
        specializationId,
        doctor,
      },
    });
  };

  // Favorite
  const toggleFavorite = (id) => {
    if (favouriteDoctorsIds.includes(id)) {
      dispatch(removeFavouriteDoctorRequest(parseInt(id, 10)));
    } else {
      dispatch(addFavouriteDoctorRequest(parseInt(id, 10)));
    }
  };

  const showPopUp = () => {
    setPopEnabled(true);
  };

  // Get Specialization Doctors based on Medic Filters change or Language change
  useEffect(() => {
    const { specialization, organization, area, doctorNameSearch: searchName } = medicFilters;
    // @ts-ignore
    if ((filterSearch !== '' && searchName?.length > 2) || filterSearch === '') {
      dispatch(
        getSpecializationDoctorsRequest({
          specialization,
          organization,
          searchName,
          area,
          sortingOptions: sortOption,
        }),
      );
    }
  }, [medicFilters, i18next.language]);

  // Get Specializations
  useEffect(() => {
    dispatch(
      getSpecializationsRequest({
        searchName: specializationSearch,
      }),
    );
  }, [specializationSearch, i18next.language]);

  // Get Favourite Doctors
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getFavouriteDoctorsRequest({ limit: 500 }));
    }
  }, [i18next.language]);

  // Search
  const handleSearchNameSubmit = (e) => {
    e.preventDefault();
    if (!doctorNameSearch) {
      setActive(false);
    }
    setMedicFilters({
      ...medicFilters,
      doctorNameSearch,
    });
  };
  const handleSearchNameChange = (e) => {
    setFilterSearch(e.target.value);
    setDoctorNameSearch(e.target.value);
    setMedicFilters({
      ...medicFilters,
      doctorNameSearch: e.target.value,
    });
  };

  const toggleSearchBar = () => setActive(!isActive);

  useEffect(() => {
    if (specializationParamSelected && specializationParamSelected !== '') {
      const spec = specializationFilter.options.filter((row) => {
        return (
          row.translations.find((lang) => lang.locale === i18next.language).name.toLowerCase() ===
          // @ts-ignore
          specializationParamSelected?.toLowerCase()
        );
      });

      const newValues: any[] = specializationFilterValues;

      newValues.push(spec[0].id);

      setSpecializationFilterValues(newValues);

      setMedicFilters({
        ...medicFilters,
        specialization: newValues,
      });
    }
  }, [specializationFilter]);

  // Get Filters
  useEffect(() => {
    dispatch(getFilterDoctorsRequest({}));
  }, []);

  /* Sorting */
  const sortingFilters = {
    byFirstSlot: 'Disponibilitate',
    byPriceAsc: 'Pret crescator',
    byPriceDesc: 'Pret descrescator',
    byRating: 'Recenzii',
  };
  const handleSortingFilterChange = (e) => {
    setSortOption(e);

    setIsSortingModal(false);

    setMedicFilters({
      ...medicFilters,
      sortingOptions: e,
    });
  };
  const sortingFiltersModal = () => {
    return (
      <>
        {isSortingModal && (
          <div className="filters-modal sorting-modal">
            <div className="filters-modal-title-components sorting-title">
              <div className="filters-modal-title" onClick={() => setIsSortingModal(false)}>
                Sorteaza
                <img src={upArrow} alt="upArrow" />
              </div>
              <div
                className="filters-reset"
                onClick={() => {
                  handleSortingFilterChange('');
                }}
              >
                <img src={recyclebin} alt="recyclebin" />
                Reseteaza
              </div>
            </div>

            <div className="filters-modal-row custom-control custom-checkbox orange-checkbox align-center-checkbox right-checkbox">
              <input
                className="custom-control-input"
                id="1"
                type="checkbox"
                onChange={() => {
                  if (sortOption === 'byFirstSlot') {
                    handleSortingFilterChange('');
                  } else {
                    handleSortingFilterChange('byFirstSlot');
                  }
                }}
                checked={sortOption === 'byFirstSlot'}
              />
              <label htmlFor="1" className="custom-control-label">
                <div className="info">
                  <div className="title">Disponibilitate</div>
                </div>
              </label>
            </div>

            <div className="filters-modal-row custom-control custom-checkbox orange-checkbox align-center-checkbox right-checkbox">
              <input
                className="custom-control-input"
                id="2"
                type="checkbox"
                onChange={() => {
                  if (sortOption === 'byPriceAsc') {
                    handleSortingFilterChange('');
                  } else {
                    handleSortingFilterChange('byPriceAsc');
                  }
                }}
                checked={sortOption === 'byPriceAsc'}
              />
              <label htmlFor="2" className="custom-control-label">
                <div className="info">
                  <div className="title">Pret crescator</div>
                </div>
              </label>
            </div>

            <div className="filters-modal-row custom-control custom-checkbox orange-checkbox align-center-checkbox right-checkbox">
              <input
                className="custom-control-input"
                id="3"
                type="checkbox"
                onChange={() => {
                  if (sortOption === 'byPriceDesc') {
                    handleSortingFilterChange('');
                  } else {
                    handleSortingFilterChange('byPriceDesc');
                  }
                }}
                checked={sortOption === 'byPriceDesc'}
              />
              <label htmlFor="3" className="custom-control-label">
                <div className="info">
                  <div className="title">Pret descrescator</div>
                </div>
              </label>
            </div>

            <div className="filters-modal-row custom-control custom-checkbox orange-checkbox align-center-checkbox right-checkbox">
              <input
                className="custom-control-input"
                id="4"
                type="checkbox"
                onChange={() => {
                  if (sortOption === 'byRating') {
                    handleSortingFilterChange('');
                  } else {
                    handleSortingFilterChange('byRating');
                  }
                }}
                checked={sortOption === 'byRating'}
              />
              <label htmlFor="4" className="custom-control-label">
                <div className="info">
                  <div className="title">Recenzii</div>
                </div>
              </label>
            </div>
          </div>
        )}
      </>
    );
  };

  // Specializations Filter
  const handleSpecializationFilterChange = (e) => {
    let newValues: any[] = specializationFilterValues;

    if (e === '') {
      newValues = [];
    } else if (specializationFilterValues.includes(e)) {
      newValues = specializationFilterValues.filter((val) => {
        return val !== e;
      });
    } else {
      newValues.push(e);
    }

    setSpecializationFilterValues(newValues);

    setIsSpecializationModal(false);

    setMedicFilters({
      ...medicFilters,
      specialization: newValues,
    });
  };
  const specializationFiltersModal = () => {
    return (
      <>
        {isSpecializationModal && (
          <div className="filters-modal specializations-modal">
            <div className="filters-modal-title-components sorting-title">
              <div className="filters-modal-title" onClick={() => setIsSpecializationModal(false)}>
                Specializari
                <img src={upArrow} alt="upArrow" />
              </div>
              <div
                className="filters-reset"
                onClick={() => {
                  handleSpecializationFilterChange('');
                  setIsSpecializationModal(false);
                }}
              >
                <img src={recyclebin} alt="recyclebin" />
                Sterge toate filtrele
              </div>
            </div>

            {specializationFilter.options.map((specializationF) => {
              return (
                <div className="filters-modal-row custom-control custom-checkbox orange-checkbox align-center-checkbox right-checkbox">
                  <input
                    className="custom-control-input"
                    id={`specializationF_${specializationF.id}`}
                    type="checkbox"
                    onChange={() => {
                      handleSpecializationFilterChange(specializationF.id);
                      setIsSortingModal(false);
                    }}
                    checked={specializationFilterValues.includes(specializationF.id)}
                  />
                  <label
                    htmlFor={`specializationF_${specializationF.id}`}
                    className="custom-control-label"
                  >
                    <div className="info">
                      <div className="title">
                        {specializationF.translations.find((row) => row.locale === i18next.language)
                          ?.name ?? ''}
                      </div>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  // Organization Filter
  const handleOrganizationFilterChange = (e) => {
    let newValues: any[] = organizationFilterValues;

    if (e === '') {
      newValues = [];
    } else if (organizationFilterValues.includes(e)) {
      newValues = organizationFilterValues.filter((val) => {
        return val !== e;
      });
    } else {
      newValues.push(e);
    }

    console.log('newValues', newValues);
    setOrganizationFilterValues(newValues);

    setIsOrganizationModal(false);

    setMedicFilters({
      ...medicFilters,
      organization: newValues,
    });
  };
  const organizationFiltersModal = () => {
    return (
      <>
        {isOrganizationModal && (
          <div className="filters-modal organizations-modal">
            <div className="filters-modal-title-components sorting-title">
              <div className="filters-modal-title" onClick={() => setIsOrganizationModal(false)}>
                Spitale partenere
                <img src={upArrow} alt="upArrow" />
              </div>
              <div
                className="filters-reset"
                onClick={() => {
                  handleOrganizationFilterChange('');
                  setIsOrganizationModal(false);
                }}
              >
                <img src={recyclebin} alt="recyclebin" />
                Sterge toate filtrele
              </div>
            </div>

            {organizationFilter.options.map((organbizationF) => {
              return (
                <div className="filters-modal-row custom-control custom-checkbox orange-checkbox align-center-checkbox right-checkbox">
                  <input
                    className="custom-control-input"
                    id={`organbizationF_${organbizationF.id}`}
                    type="checkbox"
                    onChange={() => {
                      handleOrganizationFilterChange(organbizationF.id);
                      setIsOrganizationModal(false);
                    }}
                    checked={organizationFilterValues.includes(organbizationF.id)}
                  />
                  <label
                    htmlFor={`organbizationF_${organbizationF.id}`}
                    className="custom-control-label"
                  >
                    <div className="info">
                      <div className="title">{organbizationF.name}</div>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  // Area Filter
  const handleAreaFilterChange = (e) => {
    let newValues: any[] = areaFilterValues;

    if (e === '') {
      newValues = [];
    } else if (areaFilterValues.includes(e)) {
      newValues = areaFilterValues.filter((val) => {
        return val !== e;
      });
    } else {
      newValues.push(e);
    }

    setAreaFilterValues(newValues);

    setIsAreaModal(false);

    setMedicFilters({
      ...medicFilters,
      area: newValues,
    });
  };
  const areaFiltersModal = () => {
    return (
      <>
        {isAreaModal && (
          <div className="filters-modal area-modal">
            <div className="filters-modal-title-components sorting-title">
              <div className="filters-modal-title" onClick={() => setIsAreaModal(false)}>
                Oras resedinta medic
                <img src={upArrow} alt="upArrow" />
              </div>
              <div
                className="filters-reset"
                onClick={() => {
                  handleAreaFilterChange('');
                  setIsAreaModal(false);
                }}
              >
                <img src={recyclebin} alt="recyclebin" />
                Sterge toate filtrele
              </div>
            </div>

            {areaFilter.options.map((areaF) => {
              return (
                <div className="filters-modal-row custom-control custom-checkbox orange-checkbox align-center-checkbox right-checkbox">
                  <input
                    className="custom-control-input"
                    id={`areaF_${areaF.id}`}
                    type="checkbox"
                    onChange={() => {
                      handleAreaFilterChange(areaF.id);
                      setIsAreaModal(false);
                    }}
                    checked={areaFilterValues.includes(areaF.id)}
                  />
                  <label htmlFor={`areaF_${areaF.id}`} className="custom-control-label">
                    <div className="info">
                      <div className="title">{areaF.name}</div>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="doctors-page">
      <section className="menu-section">
        <PublicHeaderMenu />
      </section>

      {/* Doctors Page Title */}
      <section className="doctors-page-title">
        <div className="doctors-page-title-content">
          <div className="title-and-breadcrumbs">
            {/* Breadcrumbs */}
            <div className="breadcrumb">
              <div className="breadcrumb breadcrumb-main-page">
                <div className="breadcrumb-item">
                  <Link to="/">{t('breadcrumbs.homeBreadcrumbs')}</Link>
                </div>
                <div className="breadcrumb-item">
                  <Link reloadDocument to="/patient/medicList">
                    {t('breadcrumbs.doctors')}
                  </Link>
                </div>
                {/* {medicFilters.specializationName && ( */}
                {/*  <div className="breadcrumb-item"> */}
                {/*    <Link to={`/patient/medicList/${medicFilters.specializationName}`}> */}
                {/*      {medicFilters.specializationName} */}
                {/*    </Link> */}
                {/*  </div> */}
                {/* )} */}
              </div>
            </div>
            {/* END Breadcrumbs */}
            <div className="title">Lista medici - Consultatii video online</div>
          </div>
          <div className="how-does-it-works">
            <div className="white-button-new" onClick={showPopUp}>
              <img src={circleI} alt="info" />
              <div className="text">{t('howItWorks.patient.title')}</div>
            </div>
          </div>
        </div>
      </section>

      <section className="filters">
        <div className="filters-container">
          {/* Search */}
          <div className="filters-search">
            <form className="searchbar" onSubmit={handleSearchNameSubmit}>
              <input
                type="text"
                placeholder={t('searchDoctor')}
                value={doctorNameSearch}
                onChange={handleSearchNameChange}
              />
              <img src={magnifier} alt="magnfier" />
            </form>
          </div>
          {/* Sorting */}
          <div className="filters-sorting">
            <div
              className="sorting-elements"
              onClick={() => {
                setIsSortingModal(true);
              }}
            >
              <div className="text-style">
                {sortOption !== '' ? t(sortingFilters[sortOption]) : 'Sorteaza dupa...'}
              </div>
              <img src={sorting} alt="sorting" />
            </div>
            {sortingFiltersModal()}
          </div>
          {/* Specializations Filter */}
          <div className="filters-specializations">
            <div
              className="specializations-elements"
              onClick={() => {
                setIsSpecializationModal(true);
              }}
            >
              <div className="text-style">Specializari</div>
              <img src={downArrow} alt="specializations" />
            </div>
            {specializationFiltersModal()}
          </div>

          {/* Organization Filter */}
          <div className="filters-organizations">
            <div
              className="organizations-elements"
              onClick={() => {
                setIsOrganizationModal(true);
              }}
            >
              <div className="text-style">Spitale</div>
              <img src={downArrow} alt="organizations" />
            </div>
            {organizationFiltersModal()}
          </div>
          <div className="filters-cities">
            <div
              className="cities-elements"
              onClick={() => {
                setIsAreaModal(true);
              }}
            >
              <div className="text-style">Orase</div>
              <img src={downArrow} alt="cities" />
            </div>
            {areaFiltersModal()}
          </div>
          <div className="filters-others">
            <div className="others-elements">
              <div className="text-style" />
              <img src={dots} alt="others" />
            </div>
          </div>
        </div>
      </section>

      <section className="doctors-list">
        <div className="doctors-list-container">
          <InfiniteScroll
            dataLength={doctors.length}
            next={loadMore}
            hasMore={currentPage <= noOfPages}
            loader={<LoadingSpinner isLoading />}
            className=""
          >
            {doctors.map((doctor) => {
              const doctorIsFavorite = favouriteDoctorsIds.includes(doctor.id);
              if (
                doctor.availability !== 'online' &&
                doctor.availability !== 'busy' &&
                haveActiveDoctors
              ) {
                haveActiveDoctors = false;
                return (
                  <Fragment key={_.uniqueId()}>
                    {/* <FindADoctorCard */}
                    {/*  specialization={medicFilters.specializationName} */}
                    {/*  specializations={specializations} */}
                    {/* /> */}
                    <DoctorCard
                      key={_.uniqueId()}
                      listItem={doctor}
                      onScheduleClicked={onScheduleClicked}
                      onCallNowClicked={onCallNowClicked}
                      toggleFavorite={toggleFavorite}
                      doctorIsFavorite={doctorIsFavorite}
                      classes="card-container"
                    />
                  </Fragment>
                );
              }
              return (
                <Fragment key={_.uniqueId()}>
                  <DoctorCard
                    key={_.uniqueId()}
                    listItem={doctor}
                    onScheduleClicked={onScheduleClicked}
                    onCallNowClicked={onCallNowClicked}
                    toggleFavorite={toggleFavorite}
                    doctorIsFavorite={doctorIsFavorite}
                    classes="card-container"
                  />
                </Fragment>
              );
            })}
          </InfiniteScroll>
        </div>
      </section>

      <PublicFooterSection />
    </div>
  );
};

export default Doctors;

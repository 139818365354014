import { AnyAction } from 'redux';
import {
  AVAILABLE_DOCTORS_SUCCESS,
  GET_DOCTOR_TIME_SLOTS_SUCCESS,
  GET_FILTERS_DOCTORS_SUCCESS,
  GET_SORTING_OPTIONS_SUCCESS,
  GET_SPECIALIZATION_DOCTORS_SUCCESS,
  PATIENT_LOCALLY_CHOOSE_SPECIALIZATION,
} from 'shared/redux/types/DoctorTypes';
import { antiDuplication } from 'shared/modules/ArrayUtils';
import DataFormatter from 'shared/modules/DataFormatter';

const SpecializationDoctorsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_SPECIALIZATION_DOCTORS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'rowId')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const FiltersDoctorsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_FILTERS_DOCTORS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const SortingOptionsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_SORTING_OPTIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const DoctorTimeSlotsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_DOCTOR_TIME_SLOTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const AvailableDoctorsReducer = (
  state = { availableForCallNow: 0, availableForEmergency: 0 },
  action: AnyAction,
) => {
  switch (action.type) {
    case AVAILABLE_DOCTORS_SUCCESS:
      return DataFormatter.camelcaseObjectKeys(action.payload);
    default:
      return state;
  }
};

const PatientLocalChooseSpecializationReducer = (
  state = {},
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case PATIENT_LOCALLY_CHOOSE_SPECIALIZATION:
      return action.payload;
    default:
      return state;
  }
};

export {
  SpecializationDoctorsReducer,
  DoctorTimeSlotsReducer,
  AvailableDoctorsReducer,
  PatientLocalChooseSpecializationReducer,
  SortingOptionsReducer,
  FiltersDoctorsReducer,
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getAnalytics, logEvent } from '@firebase/analytics';
import addAppointment from 'shared/assets/icons/add-appointment.svg';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';

const DoctorCardAppointmentButton = (props) => {
  const { t } = useTranslation();
  const {
    status,
    onClickEvent,
    upcomingConsultation,
    listItemSchedule,
    centerTextButton,
    listItemType,
  } = props;

  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const availableTextTo = (item) => {
    if (item) {
      const day = dayjs(item).format('DD');
      if (day === dayjs().format('DD') || day === dayjs().add(1, 'day').format('DD')) {
        // return t('medicalApp.availability');
        return t('medicalApp.availabilityFrom');
      }
    }

    return t('medicalApp.availabilityFrom');
  };

  const callScheduleAction = () => {
    const analytics = getAnalytics();
    logEvent(analytics, 'programmed_initialized_web', {});

    onClickEvent();
  };

  return (
    <div>
      <div onClick={onClickEvent} className="white-button-new">
        <img src={addAppointment} alt="camera" className="add-appointment" />
        <div className="add-appointment-btn-text">{t('schedule')}</div>

        {/* <div className="appointment-btn-texts"> */}
        {/*  <div className="appointment-btn-texts-schedule">{t('scheduleTitle')}</div> */}
        {/*  {listItemSchedule?.firstAvailableTime && ( */}
        {/*    <div className="d-flex justify-content-between" onClick={callScheduleAction}> */}
        {/*      <div className="subtitle"> */}
        {/*        {listItemSchedule.rawFirstAvailableTime && */}
        {/*          availableTextTo(listItemSchedule.rawFirstAvailableTime)} */}
        {/*        {` ${listItemSchedule.firstAvailableTime}`} */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  )} */}
        {/* </div> */}
      </div>
      <div className="schedule-availability">
        {listItemSchedule?.firstAvailableTime && (
          <div>
            {listItemSchedule.rawFirstAvailableTime &&
              availableTextTo(listItemSchedule.rawFirstAvailableTime)}
            {` ${listItemSchedule.firstAvailableTime}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default DoctorCardAppointmentButton;

import 'patient/styles/consultations.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  getPatientPastAppointmentsState,
  getPatientUpcomingAppointmentsState,
} from 'shared/redux/src/StatesGetter';
import InfiniteScroll from 'react-infinite-scroll-component';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';
import {
  fetchPatientPastAppointmentsRequest,
  fetchPatientUpcomingAppointmentsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import UpcomingConsultationListItem from 'shared/components/consultations/UpcomingConsultationListItem';
import PastConsultationListItem from 'shared/components/consultations/PastConsultationListItem';
import Utils from 'shared/modules/Utils';
import { requestAppointment } from 'shared/modules/PatientAppointmentUtils';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const ConsultationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('upcoming');
  const {
    data: upcomingConsultations,
    currentPage: upcomingConsultationsCurrentPage,
    noOfPages: upcomingConsultationsNoOfPages,
  } = useSelector(getPatientUpcomingAppointmentsState, shallowEqual);
  const {
    data: pastConsultations,
    currentPage: pastConsultationsCurrentPage,
    noOfPages: pastConsultationsNoOfPages,
  } = useSelector(getPatientPastAppointmentsState, shallowEqual);
  const [formattedPastConsultations, setFormattedPastConsultations] = useState(
    Utils.makeSectionListFormat(pastConsultations, 'dateFormatted'),
  );

  const [formattedUpcomingConsultations, setFormattedUpcomingConsultations] = useState(
    Utils.makeSectionListFormat(upcomingConsultations, 'dateFormattedFull'),
  );

  const handleActiveTabChange = (newTab) => {
    if (newTab) {
      setActiveTab(newTab);
    }
  };

  const loadNextPagePastAppointments = () => {
    if (pastConsultationsCurrentPage <= pastConsultationsNoOfPages) {
      dispatch(
        fetchPatientPastAppointmentsRequest({
          page: pastConsultationsCurrentPage,
          nextPage: true,
        }),
      );
    }
  };

  const loadNextPageUpcomingAppointments = () => {
    if (upcomingConsultationsCurrentPage <= upcomingConsultationsNoOfPages) {
      dispatch(
        fetchPatientUpcomingAppointmentsRequest({
          page: upcomingConsultationsCurrentPage,
          nextPage: true,
        }),
      );
    }
  };

  const fetchUpcomingAppointments = () => dispatch(fetchPatientUpcomingAppointmentsRequest({}));
  const fetchPastAppointments = () => dispatch(fetchPatientPastAppointmentsRequest({}));

  const renderPastItem = (item) => {
    const { doctor, user } = item;

    return (
      <PastConsultationListItem isDoctor={false} listItem={item} user={doctor ?? user ?? {}} />
    );
  };

  const onCallNowClicked = (doctor, specializationId) => {
    requestAppointment('callNow', {
      forRequest: {
        doctor: `${doctor?.id}`,
        specialization: `${specializationId}`,
      },
      forUpdate: {
        specializationId,
        doctor,
      },
    });
  };

  const renderUpcomingItem = (item) => {
    const { user, doctor } = item;

    return (
      <UpcomingConsultationListItem
        onCallNowClicked={onCallNowClicked}
        listItem={{ ...item, isNextScheduledAppointment: false }}
        isDoctor={false}
        user={doctor ?? user ?? {}}
      />
    );
  };

  useEffect(() => {
    fetchUpcomingAppointments();
    fetchPastAppointments();

    const getActiveTabFromNavigation = location.state as string;
    const getSavedActiveTab = localStorage.getItem('patientConsultationsActiveTab');

    if (getActiveTabFromNavigation) {
      setActiveTab(getActiveTabFromNavigation);
      navigate(location.pathname, { replace: true });
    } else if (getSavedActiveTab) {
      setActiveTab(getSavedActiveTab);
    }
  }, [i18next.language]);

  useEffect(() => {
    localStorage.setItem('patientConsultationsActiveTab', activeTab);
  }, [activeTab]);

  useEffect(() => {
    setFormattedPastConsultations(Utils.makeSectionListFormat(pastConsultations, 'dateFormatted'));
  }, [pastConsultations]);

  useEffect(() => {
    setFormattedUpcomingConsultations(
      Utils.makeSectionListFormat(upcomingConsultations, 'dateFormattedFull'),
    );
  }, [upcomingConsultations]);

  console.log('formattedUpcomingConsultations', formattedUpcomingConsultations);

  return (
    <section className="consultations patient">
      <div className="title-section">
        <h1>{t('appointments.appointments')}</h1>
      </div>

      <Tab.Container
        id="consultations-tabs-menu"
        activeKey={activeTab}
        onSelect={handleActiveTabChange}
      >
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="upcoming" onClick={fetchUpcomingAppointments}>
              {t('mainScreenRoutes.appointments')}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="history">{t('appointments.pastAppointments')}</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="upcoming">
            {activeTab === 'upcoming' && (
              <InfiniteScroll
                dataLength={upcomingConsultations.length}
                next={loadNextPageUpcomingAppointments}
                hasMore={upcomingConsultationsCurrentPage <= upcomingConsultationsNoOfPages}
                loader={<LoadingSpinner isLoading />}
                scrollableTarget="infiniteScrollContainerPastConsultations"
              >
                {!upcomingConsultations.length && (
                  <div className="empty-container">{t('appointments.noAppointments')}</div>
                )}
                {formattedUpcomingConsultations.map((monthConsultations) => (
                  <div key={monthConsultations.key}>
                    <div className="subtitle">{monthConsultations.key}</div>
                    {monthConsultations?.data?.map((consultation) =>
                      renderUpcomingItem(consultation),
                    )}
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </Tab.Pane>

          <Tab.Pane eventKey="history">
            {activeTab === 'history' && (
              <InfiniteScroll
                dataLength={pastConsultations.length}
                next={loadNextPagePastAppointments}
                hasMore={pastConsultationsCurrentPage <= pastConsultationsNoOfPages}
                loader={<LoadingSpinner isLoading />}
              >
                {!pastConsultations.length && (
                  <div className="empty-container">{t('appointments.noAppointments')}</div>
                )}
                {formattedPastConsultations.map((monthConsultations) => (
                  <div key={monthConsultations.key}>
                    <div className="subtitle">{monthConsultations.key}</div>
                    {monthConsultations?.data?.map((consultation) => renderPastItem(consultation))}
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </section>
  );
};

export default ConsultationList;

import { Action, combineReducers, Reducer, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PERSIST } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'shared/redux/saga';
import AuthReducer from 'shared/redux/reducers/AuthReducer';
import CountryReducer from 'shared/redux/reducers/CountryReducer';
import { errorReducer, loadingReducer } from 'shared/redux/src/GenericReducer';
import {
  AssociateSpecializationReducer,
  LocalCityReducer,
  LocalSpecializationReducer,
  MySpecializationsReducer,
  SetAvailabilityReducer,
  TitlesReducer,
  UserProfileReducer,
} from 'shared/redux/reducers/UserProfileReducer';
import MyReviewsReducer from 'shared/redux/reducers/MyReviewsReducer';
import {
  CancelAllAppointmentsByDoctorReducer,
  CancelAppointmentByDoctorReducer,
  DoctorAppointmentDetailsReducer,
  DoctorAppointmentReducer,
  DoctorCallsStackReducer,
  DoctorPastAppointmentsReducer,
  DoctorTodayAppointmentsReducer,
  DoctorUpcomingAppointmentsReducer,
} from 'shared/redux/reducers/DoctorAppointmentsReducer';
import { TwilioTokenReducer } from 'shared/redux/reducers/TwilioReducer';
import { LanguageReducer, SetLanguageReducer } from 'shared/redux/reducers/LanguageReducer';
import {
  NotificationsSettingsReducer,
  PushesReducer,
} from 'shared/redux/reducers/NotificationReducer';
import {
  SpecializationsReducer,
  SpecializationsHomeReducer,
} from 'shared/redux/reducers/SpecializationReducer';
import {
  DoctorExceptionTimetableReducer,
  DoctorPastTimetableReducer,
  DoctorRecurrenceTimetableReducer,
  DoctorTimetableReducer,
  LocalDateReducer,
} from 'shared/redux/reducers/DoctorTimetableReducer';
import PossibleKinshipReducer from 'shared/redux/reducers/KinshipReducer';
import {
  AppointmentSettingsReducer,
  SharedAppointmentReducer,
} from 'shared/redux/reducers/SharedAppointmentReducer';
import {
  BalanceReducer,
  IncomeReducer,
  IncomeTransactionsMonthlyReducer,
  IncomeTransactionsReducer,
  PayoutsReducer,
  PayoutTransactionsReducer,
} from 'shared/redux/reducers/TransactionReducer';
import CurrencyReducer from 'shared/redux/reducers/CurrencyReducer';
import SharedStateReducer from 'shared/redux/reducers/SharedStateReducer';
import {
  AboutUsReducer,
  PrivacyPolicyReducer,
  StripeTermsReducer,
  TermsReducer,
  GDPRReducer,
  TwilioTermsReducer,
  CampaignRulesReducer,
} from 'shared/redux/reducers/StaticPagesReducer';
import RemoteConfigReducer from 'shared/redux/reducers/RemoteConfigReducer';
import {
  PatientMedicalRecordsReducer,
  PatientProfileReducer,
  LocalCurrentPatientProfileReducer,
} from 'shared/redux/reducers/PatientProfileReducer';
import DoctorProfileReducer from 'shared/redux/reducers/DoctorProfileReducer';
import DoctorReviewsReducer from 'shared/redux/reducers/DoctorReviewsReducer';
import {
  AvailableDoctorsReducer,
  DoctorTimeSlotsReducer,
  FiltersDoctorsReducer,
  PatientLocalChooseSpecializationReducer,
  SortingOptionsReducer,
  SpecializationDoctorsReducer,
} from 'shared/redux/reducers/DoctorReducer';
import FavouriteDoctorsReducer from 'shared/redux/reducers/FavouriteDoctorsReducer';
import {
  FamilyMembersReducer,
  ProfileDetailsReducer,
} from 'shared/redux/reducers/FamilyMembersReducer';
import SupportReducer from 'shared/redux/reducers/SupportReducer';
import {
  LocalChooseCardReducer,
  PatientPaymentReducer,
  PatientTransactionsReducer,
  PaymentMethodsReducer,
} from 'shared/redux/reducers/PatientPaymentReducer';
import {
  PatientAppointmentDetailsReducer,
  PatientAppointmentReducer,
  PatientPastAppointmentsReducer,
  PatientUpcomingAppointmentsReducer,
  PendingAppointmentReducer,
} from 'shared/redux/reducers/PatientAppointmentReducer';
import MedicalRecordCategoryReducer from 'shared/redux/reducers/MedicalRecordCategoryReducer';
import {
  ApplyVoucherReducer,
  LocalChooseVoucherReducer,
  ValidVouchersReducer,
  VouchersReducer,
} from 'shared/redux/reducers/VouchersReducer';
import RecommendationsReducer from 'shared/redux/reducers/RecommendationsReducer';
import * as localForage from 'localforage';
import { FORCE_LOGOUT_SUCCESS, LOGOUT_SUCCESS } from './types/AuthTypes';
import {
  AllDoctorsMlmReducer,
  MLMAcceptedInvitationsReducer,
  MLMAddReferralReducer,
  MLMBonusesReducer,
  MLMSendInviteReducer,
  MLMSentInvitationsReducer,
} from './reducers/ReferralReducer';
import TranslationsReducer from './reducers/TranslationsReducer';
import AllReviewsReducer from './reducers/AllReviewsReducer';
import SettingsReducer from './reducers/SettingsReducer';
import { ServiceReducer } from './reducers/ServiceReducer';
import { VideoCallTokenReducer } from './reducers/VideoCallReducer';
import PrescriptionReducer from './reducers/PrescriptionReducer';
import { ParticipantPlatformReducer } from './reducers/ParticipantPlatformReducer';

const sagaMiddleware = createSagaMiddleware();

const persistRootKey = `Ringdoc_web`;

const appReducer = combineReducers({
  loadingState: loadingReducer,
  errorState: errorReducer,
  authState: AuthReducer as Reducer<void, Action>,
  sharedState: SharedStateReducer as Reducer<void, Action>,
  userProfileState: UserProfileReducer as Reducer<void, Action>,
  recommendationsState: RecommendationsReducer as Reducer<void, Action>,
  availabilityState: SetAvailabilityReducer as Reducer<void, Action>,
  associateSpecialization: AssociateSpecializationReducer as Reducer<void, Action>,
  myReviewsState: MyReviewsReducer as Reducer<void, Action>,
  doctorPastAppointmentsState: DoctorPastAppointmentsReducer as Reducer<void, Action>,
  doctorUpcomingAppointmentsState: DoctorUpcomingAppointmentsReducer as Reducer<void, Action>,
  doctorTodayAppointmentsState: DoctorTodayAppointmentsReducer as Reducer<void, Action>,
  VideoCallTokenState: VideoCallTokenReducer as Reducer<void, Action>,
  countriesState: CountryReducer as Reducer<void, Action>,
  languagesState: LanguageReducer as Reducer<void, Action>,
  pushesState: PushesReducer as Reducer<void, Action>,
  setLanguageState: SetLanguageReducer as Reducer<void, Action>,
  specializationsState: SpecializationsReducer as Reducer<void, Action>,
  specializationsHomeState: SpecializationsHomeReducer as Reducer<void, Action>,
  doctorAppointmentDetailsState: DoctorAppointmentDetailsReducer as Reducer<void, Action>,
  cancelAppointmentByDoctorState: CancelAppointmentByDoctorReducer as Reducer<void, Action>,
  localSpecializationState: LocalSpecializationReducer as Reducer<void, Action>,
  doctorTimetableState: DoctorTimetableReducer as Reducer<void, Action>,
  cancelAllAppointmentsByDoctorState: CancelAllAppointmentsByDoctorReducer as Reducer<void, Action>,
  mySpecializationsState: MySpecializationsReducer as Reducer<void, Action>,
  doctorAppointmentState: DoctorAppointmentReducer as Reducer<void, Action>,
  prescriptionState: PrescriptionReducer as Reducer<void, Action>,
  doctorCallsStackState: DoctorCallsStackReducer as Reducer<void, Action>,
  profileDetailsState: ProfileDetailsReducer as Reducer<void, Action>,
  possibleKinshipState: PossibleKinshipReducer as Reducer<void, Action>,
  localCurrentPatientProfileState: LocalCurrentPatientProfileReducer as Reducer<void, Action>,
  sharedAppointmentState: SharedAppointmentReducer as Reducer<void, Action>,
  balanceState: BalanceReducer as Reducer<void, Action>,
  payoutsState: PayoutsReducer as Reducer<void, Action>,
  payoutTransactionsState: PayoutTransactionsReducer as Reducer<void, Action>,
  incomeState: IncomeReducer as Reducer<void, Action>,
  incomeTransactionsState: IncomeTransactionsReducer as Reducer<void, Action>,
  incomeTransactionsMonthlyState: IncomeTransactionsMonthlyReducer as Reducer<void, Action>,
  notificationsSettingsState: NotificationsSettingsReducer as Reducer<void, Action>,
  appointmentSettingsState: AppointmentSettingsReducer as Reducer<void, Action>,
  currencyState: CurrencyReducer as Reducer<void, Action>,
  remoteConfigState: RemoteConfigReducer as Reducer<void, Action>,
  aboutUsState: AboutUsReducer as Reducer<void, Action>,
  privacyPolicyState: PrivacyPolicyReducer as Reducer<void, Action>,
  termsState: TermsReducer as Reducer<void, Action>,
  GDPRState: GDPRReducer as Reducer<void, Action>,
  CampaignRulesState: CampaignRulesReducer as Reducer<void, Action>,
  stripeTermsState: StripeTermsReducer as Reducer<void, Action>,
  twilioTermsState: TwilioTermsReducer as Reducer<void, Action>,
  patientProfileState: PatientProfileReducer as Reducer<void, Action>,
  doctorProfileState: DoctorProfileReducer as Reducer<void, Action>,
  doctorReviewsState: DoctorReviewsReducer as Reducer<void, Action>,
  availableDoctorsState: AvailableDoctorsReducer as Reducer<void, Action>,
  favouriteDoctorsState: FavouriteDoctorsReducer as Reducer<void, Action>,
  specializationDoctorsState: SpecializationDoctorsReducer as Reducer<void, Action>,
  filtersDoctorsState: FiltersDoctorsReducer as Reducer<void, Action>,
  sortingOptionsState: SortingOptionsReducer as Reducer<void, Action>,
  familyMembersState: FamilyMembersReducer as Reducer<void, Action>,
  paymentState: PatientPaymentReducer as Reducer<void, Action>,
  supportState: SupportReducer as Reducer<void, Action>,
  patientLocalChooseSpecializationState: PatientLocalChooseSpecializationReducer as Reducer<
    void,
    Action
  >,
  patientAppointmentState: PatientAppointmentReducer as Reducer<void, Action>,
  patientPastAppointmentsState: PatientPastAppointmentsReducer as Reducer<void, Action>,
  patientUpcomingAppointmentsState: PatientUpcomingAppointmentsReducer as Reducer<void, Action>,
  patientAppointmentDetailsState: PatientAppointmentDetailsReducer as Reducer<void, Action>,
  doctorTimeSlotsState: DoctorTimeSlotsReducer as Reducer<void, Action>,
  paymentMethodsState: PaymentMethodsReducer as Reducer<void, Action>,
  medicalRecordCategoryState: MedicalRecordCategoryReducer as Reducer<void, Action>,
  patientTransactionsState: PatientTransactionsReducer as Reducer<void, Action>,
  patientMedicalRecordsState: PatientMedicalRecordsReducer as Reducer<void, Action>,
  participantPlatformState: ParticipantPlatformReducer as Reducer<void, Action>,
  applyVoucherState: ApplyVoucherReducer as Reducer<void, Action>,
  vouchersState: VouchersReducer as Reducer<void, Action>,
  validVouchersState: ValidVouchersReducer as Reducer<void, Action>,
  localChooseVoucherState: LocalChooseVoucherReducer as Reducer<void, Action>,
  localChooseCardState: LocalChooseCardReducer as Reducer<void, Action>,
  localCityState: LocalCityReducer as Reducer<void, Action>,
  localDateState: LocalDateReducer as Reducer<void, Action>,
  doctorPastTimetableState: DoctorPastTimetableReducer as Reducer<void, Action>,
  titlesState: TitlesReducer as Reducer<void, Action>,
  doctorExceptionTimetableState: DoctorExceptionTimetableReducer as Reducer<void, Action>,
  doctorRecurrenceTimetableState: DoctorRecurrenceTimetableReducer as Reducer<void, Action>,
  translationsState: TranslationsReducer as Reducer<void, Action>,
  mlmBonusesState: MLMBonusesReducer as Reducer<void, Action>,
  mlmSendInviteState: MLMSendInviteReducer as Reducer<void, Action>,
  mlmAcceptedInvitationsState: MLMAcceptedInvitationsReducer as Reducer<void, Action>,
  mlmSentInvitationsState: MLMSentInvitationsReducer as Reducer<void, Action>,
  mlmAddReferralState: MLMAddReferralReducer as Reducer<void, Action>,
  allDoctorsMlmState: AllDoctorsMlmReducer as Reducer<void, Action>,
  allReviewsState: AllReviewsReducer as Reducer<void, Action>,
  pendingAppointmentState: PendingAppointmentReducer as Reducer<void, Action>,
  settingsState: SettingsReducer as Reducer<void, Action>,
  serviceState: ServiceReducer as Reducer<void, Action>,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS || action.type === FORCE_LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: persistRootKey,
  storage: localForage,
  version: 1,
  blacklist: ['loadingState', 'errorState'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store: Store = configureStore({
  reducer: persistedReducer,
  devTools: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      // serializableCheck: {
      //   ignoredActions: [PERSIST],
      //   ignoredActionPaths: ['payload.confirmPayment', 'payload.toUpload', 'payload.files'], // PatientPaymentSaga - Stripe Promise, Upload file
      // },
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

const storeDispatcher = (action) => store.dispatch(action);

const storeStateGetter = (state) => store.getState()[state];

export { persistor, store, storeStateGetter, storeDispatcher };

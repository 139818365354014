import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import chip from 'shared/assets/icons/Chip.svg';
import spitalImg from 'shared/assets/images/spital.png';
import { isMobile } from 'react-device-detect';
import CustomIcon from './CustomIcon';
import Colors from '../themes/Colors';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { getEnv } from '../services/EnvService';
import patientPermImage from '../assets/images/acces-permissions-patient-image.png';

const PublicReputationSlider = () => {
  const caruselObject = [
    {
      name: 'Spitalul Universitare de Urgenta',
    },
    {
      name: 'Spitalul Clinic de Urgenta pentru copii',
    },
    {
      name: 'Spitalul de Boli Cronice Smeeni',
    },
    {
      name: 'Spitalul FLoreasca',
    },
  ];

  const renderArrowLeft = () => {
    return (
      <div className="prev-slider">
        <CustomIcon
          className="custom-icon rotate-180"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };
  const renderArrowRight = () => {
    return (
      <div className="next-slider">
        <CustomIcon
          className="custom-icon"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };

  return (
    <>
      <section className="reputation">
        <div className="reputation-container">
          <div className="reputation-container-title">Medici din spitale de renume</div>
          <Swiper
            // install Swiper modules
            modules={[Navigation]}
            spaceBetween={16}
            slidesPerView={isMobile ? 1.2 : 3.2}
            navigation={{
              nextEl: '.custom-next',
              prevEl: '.custom-prev',
            }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            className="reputation-swiper"
          >
            <SwiperSlide>
              <div className="item active">
                <div className="content">
                  <div className="info">
                    <div className="hospital-name">Spitalul Universitar de Urgență</div>
                    <div className="hospital-town">Bucuresti</div>
                    <div className="hospital-doctors-info">
                      <div className="all-doctors">32 medici</div>
                      <div className="online-doctors">
                        <img
                          src={chip}
                          style={{ width: '16px', height: '16px', marginRight: '3px' }}
                          alt="chip"
                        />
                        5 online
                      </div>
                    </div>
                  </div>
                  <div className="img">
                    <img src={spitalImg} alt="hospitalImg" />
                  </div>
                </div>
                <div className="btn-container">
                  <div className="btn">Vezi medicii de la acest spital</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="content">
                  <div className="info">
                    <div className="hospital-name">
                      Spitalul Clinic de Urgenta pentru Copii Louis Țurcanu
                    </div>
                    <div className="hospital-town">Timisoara</div>
                    <div className="hospital-doctors-info">
                      <div className="all-doctors">16 medici</div>
                      <div className="online-doctors">
                        <img
                          src={chip}
                          style={{ width: '16px', height: '16px', marginRight: '3px' }}
                          alt="chip"
                        />
                        3 online
                      </div>
                    </div>
                  </div>
                  <div className="img">
                    <img src={spitalImg} alt="hospitalImg" />
                  </div>
                </div>
                <div className="btn-container">
                  <div className="btn">Vezi medicii de la acest spital</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="content">
                  <div className="info">
                    <div className="hospital-name">Spitalul Universitar de Urgență</div>
                    <div className="hospital-town">Bucuresti</div>
                    <div className="hospital-doctors-info">
                      <div className="all-doctors">32 medici</div>
                      <div className="online-doctors">
                        <img
                          src={chip}
                          style={{ width: '16px', height: '16px', marginRight: '3px' }}
                          alt="chip"
                        />
                        5 online
                      </div>
                    </div>
                  </div>
                  <div className="img">
                    <img src={spitalImg} alt="hospitalImg" />
                  </div>
                </div>
                <div className="btn-container">
                  <div className="btn">Vezi medicii de la acest spital</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="content">
                  <div className="info">
                    <div className="hospital-name">Spitalul Universitar de Urgență</div>
                    <div className="hospital-town">Bucuresti</div>
                    <div className="hospital-doctors-info">
                      <div className="all-doctors">32 medici</div>
                      <div className="online-doctors">
                        <img
                          src={chip}
                          style={{ width: '16px', height: '16px', marginRight: '3px' }}
                          alt="chip"
                        />
                        5 online
                      </div>
                    </div>
                  </div>
                  <div className="img">
                    <img src={spitalImg} alt="hospitalImg" />
                  </div>
                </div>
                <div className="btn-container">
                  <div className="btn">Vezi medicii de la acest spital</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="content">
                  <div className="info">
                    <div className="hospital-name">Spitalul Universitar de Urgență</div>
                    <div className="hospital-town">Bucuresti</div>
                    <div className="hospital-doctors-info">
                      <div className="all-doctors">32 medici</div>
                      <div className="online-doctors">
                        <img
                          src={chip}
                          style={{ width: '16px', height: '16px', marginRight: '3px' }}
                          alt="chip"
                        />
                        5 online
                      </div>
                    </div>
                  </div>
                  <div className="img">
                    <img src={spitalImg} alt="hospitalImg" />
                  </div>
                </div>
                <div className="btn-container">
                  <div className="btn">Vezi medicii de la acest spital</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="content">
                  <div className="info">
                    <div className="hospital-name">Spitalul Universitar de Urgență</div>
                    <div className="hospital-town">Bucuresti</div>
                    <div className="hospital-doctors-info">
                      <div className="all-doctors">32 medici</div>
                      <div className="online-doctors">
                        <img
                          src={chip}
                          style={{ width: '16px', height: '16px', marginRight: '3px' }}
                          alt="chip"
                        />
                        5 online
                      </div>
                    </div>
                  </div>
                  <div className="img">
                    <img src={spitalImg} alt="hospitalImg" />
                  </div>
                </div>
                <div className="btn-container">
                  <div className="btn">Vezi medicii de la acest spital</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="custom-nav">
            {/* eslint-disable-next-line react/button-has-type */}
            <button className="custom-prev">
              <div className="icon colored" />
            </button>
            {/* eslint-disable-next-line react/button-has-type */}
            <button className="custom-next">
              <div className="icon colored" />
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default PublicReputationSlider;

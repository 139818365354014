import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import {
  AVAILABLE_DOCTORS_REQUEST,
  GET_DOCTOR_TIME_SLOTS_REQUEST,
  GET_FILTERS_DOCTORS_REQUEST,
  GET_SORTING_OPTIONS_REQUEST,
  GET_SPECIALIZATION_DOCTORS_REQUEST,
} from 'shared/redux/types/DoctorTypes';
import {
  getSpecializationDoctorsError,
  getSpecializationDoctorsSuccess,
  getDoctorTimeSlotsSuccess,
  getDoctorTimeSlotsError,
  getAvailableDoctorsError,
  getAvailableDoctorsSuccess,
  getSortingOptionsRequest,
  getSortingOptionsSuccess,
  getSortingOptionsError,
  getFiltersDoctorsError,
  getFiltersDoctorsSuccess,
} from 'shared/redux/actions/DoctorsActions';
import {
  getAvailableDoctorsAPI,
  getDoctorTimeSlotsAPI,
  getFiltersDoctorsAPI,
  getSortingOptionsAPI,
  getSpecializationDoctorsAPI,
} from 'shared/redux/api/DoctorApi';
import {
  doctorSelector,
  filtersDoctorsSelector,
  sortingSelector,
  timeSlotsSelector,
} from 'shared/redux/selector/DoctorsSelector';

function* getSpecializationDoctors(actionType) {
  const specializationDoctorsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(specializationDoctorsChannel);
    try {
      const safeAction = {
        limit: 20,
        page: 1,
        id: '',
        name: '',
        searchName: '',
        minPrice: '',
        maxPrice: '',
        rating: '',
        sortingOptions: '',
        ...payload,
      };
      const response = yield call(getSpecializationDoctorsAPI, safeAction);
      yield put(
        getSpecializationDoctorsSuccess({
          // @ts-ignore
          ...doctorSelector(response.data, safeAction?.id),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(getSpecializationDoctorsError({ message }));
    }
  }
}

function* getFiltersDoctors(actionType) {
  const filtersDoctorsChannel = yield actionChannel(actionType);
  while (true) {
    try {
      const { payload } = yield take(filtersDoctorsChannel);
      const response = yield call(getFiltersDoctorsAPI);
      yield put(getFiltersDoctorsSuccess(filtersDoctorsSelector(response.data)));
    } catch ({ message }) {
      yield put(getFiltersDoctorsError({ message }));
    }
  }
}

function* getSortingOptions(actionType) {
  const sortingOptionsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(sortingOptionsChannel);
    try {
      const response = yield call(getSortingOptionsAPI);
      yield put(getSortingOptionsSuccess(sortingSelector(response)));
    } catch ({ message }) {
      yield put(getSortingOptionsError({ message }));
    }
  }
}

function* getDoctorTimeSlots(actionType) {
  const doctorSlotsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(doctorSlotsChannel);
    try {
      const response = yield call(getDoctorTimeSlotsAPI, payload);
      yield put(getDoctorTimeSlotsSuccess(timeSlotsSelector(response.data)));
    } catch ({ message }) {
      yield put(getDoctorTimeSlotsError({ message }));
    }
  }
}

function* getAvailableDoctors(actionType) {
  const availableDoctorsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(availableDoctorsChannel);
    try {
      const response = yield call(getAvailableDoctorsAPI);
      yield put(getAvailableDoctorsSuccess(response.data));
    } catch ({ message }) {
      yield put(getAvailableDoctorsError({ message }));
    }
  }
}

function* doctorSaga() {
  yield fork(getSpecializationDoctors, GET_SPECIALIZATION_DOCTORS_REQUEST);
  yield fork(getFiltersDoctors, GET_FILTERS_DOCTORS_REQUEST);
  yield fork(getSortingOptions, GET_SORTING_OPTIONS_REQUEST);
  yield fork(getDoctorTimeSlots, GET_DOCTOR_TIME_SLOTS_REQUEST);
  yield fork(getAvailableDoctors, AVAILABLE_DOCTORS_REQUEST);
}

export default doctorSaga;

import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import StorageService from 'shared/services/StorageService';
import { getEnv } from 'shared/services/EnvService';
import Alerts from 'shared/components/Alerts';
import { updateLocaleRequest } from 'shared/redux/actions/UngroupedActions';
import { getAuthState } from 'shared/redux/src/StatesGetter';
import { toUpper } from 'lodash';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import DownArrow from 'shared/assets/icons/Down_arrow.svg';
import 'dayjs/locale/ro';
import 'dayjs/locale/en';

interface Locale {
  key: string;
  isChecked: boolean;
  translationKey: string;
}

const PublicLanguageNavSelector = ({ mobileOnly = false }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedLocale, setSelectedLocale] = useState(getEnv('LOCALE'));
  const [openLangSelector, setOpenLangSelector] = useState(false);
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const location = useLocation();

  useEffect(() => {
    StorageService.getData('APP_LANGUAGE', getEnv('LOCALE')).then((loc) => {
      setSelectedLocale(loc);
    });
  });
  const locales: Locale[] = [
    { key: 'ro', isChecked: selectedLocale === 'ro', translationKey: 'ro_RO' },
    { key: 'en', isChecked: selectedLocale === 'en', translationKey: 'en_US' },
  ];
  const updateLocale = (item) => {
    if (item.key !== selectedLocale) {
      Alerts.okCancelAlert(
        t('settings.change_language'),
        t('settings.change_language_question'),
        () => {
          StorageService.setData('APP_LANGUAGE', item.key).then(() => {
            dispatch(updateLocaleRequest({ locale: item.key, isLoggedIn }));
            setSelectedLocale(item.key);
            i18n.changeLanguage(item.key).then(() => {});

            dayjs.locale(item.key);
          });
        },
      );
    }
  };

  const useOutsideClick = (callback) => {
    const ref = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);

    return ref;
  };

  const handleClickOutside = () => {
    setOpenLangSelector(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <div className={`public-language-selector-wrapper${openLangSelector ? ' active' : ''}`}>
      <Helmet htmlAttributes={{ lang: selectedLocale }} />
      <span
        className="cursor-pointer"
        ref={ref}
        onClick={() => setOpenLangSelector(!openLangSelector)}
      >
        {toUpper(i18n.language)}
        <img
          src={DownArrow}
          alt="down-arrow"
          style={{
            width: '14px',
            height: '8px',
          }}
        />
      </span>
      {openLangSelector && (
        <div className="language-options">
          {locales.map(
            (item: Locale) =>
              item.key !== i18n.language && (
                <div
                  className="language-option cursor-pointer"
                  onClick={() => updateLocale(item)}
                  key={item.key}
                >
                  {t(`locale.${item.translationKey}`)}
                </div>
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default PublicLanguageNavSelector;

import React, { useEffect, useState } from 'react';
import star from 'shared/assets/icons/Star.svg';
import mapPoint from 'shared/assets/icons/map-point.svg';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PatientMedicCardButton from './PatientMedicCardButton';
import { setPendingAppointment } from '../../../shared/redux/actions/PatientAppointmentActions';
import Alerts from '../../../shared/components/Alerts';
import { getAuthState } from '../../../shared/redux/src/StatesGetter';
import { requestMediaCamera } from '../../../shared/modules/PermissionsUtils';
import DoctorCardCallButton from './DoctorCardCallButton';
import DoctorCardAppointmentButton from './DoctorCardAppointmentButton';

const DoctorCard = (props) => {
  const { t } = useTranslation();

  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { classes, listItem, onScheduleClicked, onCallNowClicked } = props;
  const { isAvailableForCallNow, hasSchedule, specializationId } = listItem;

  const onSchedule = () => {
    if (!isLoggedIn) {
      dispatch(setPendingAppointment({ type: 'programmed', doctor: listItem, specializationId }));
      Alerts.okCancelAlert(t('info'), t('alerts.youNeedToLogin'), () => {
        localStorage.setItem('CURRENT_LINK', `${window.location.pathname}`);
        navigate('/patient/login');
      });
    }

    let hasControl = false;
    if (
      listItem.doctorServices?.filter((service) => {
        return service.slug === 'control';
      }).length > 0
    ) {
      hasControl = true;
    }
    onScheduleClicked(listItem, specializationId, hasControl);
  };

  const onCall = () => {
    if (!isLoggedIn) {
      dispatch(setPendingAppointment({ type: 'callNow', doctor: listItem, specializationId }));
      Alerts.okCancelAlert(t('info'), t('alerts.youNeedToLogin'), () => {
        localStorage.setItem('CURRENT_LINK', `${window.location.pathname}`);

        navigate('/patient/login');
      });
    }

    if (isAvailableForCallNow) {
      requestMediaCamera().then((response) => {
        if (!response) {
          window.location.href = '/support/permissions';
        } else {
          onCallNowClicked(listItem, specializationId);
        }
      });
    }
  };

  return (
    <div className={classes}>
      <div className="box">
        <div className="doctor-box">
          <div className="doctor-box-info">
            <div className="full-name">{listItem.fullNameWithTitle}</div>
            <div className="specialization-name">{listItem.specializationName}</div>
            <div className="rating">
              <img src={star} alt="star" />
              <div className="rating-value">{listItem.averageRating.substring(0, 3)}</div>
              <div className="rating-separator" />
              <div className="rating-count">{`${listItem.countRating} ${t('reviews')}`}</div>
            </div>
            <div className="map-info">
              <img src={mapPoint} alt="location" />
              <div className="map-value">{listItem.workingArea?.name}</div>
            </div>
            <div className="organization-info">
              <div className="organization-value">{listItem.organization?.name}</div>
            </div>
          </div>
          <div className="doctor-box-img">
            <div className="doctor">
              <img src={listItem.imgUrl} alt="doctor" />
            </div>
            <div className="organization">
              <img src={listItem.organization?.logo_media?.public_url} alt="organization" />
            </div>
          </div>
        </div>
        <div className="appointment-box">
          <div className="services">
            {listItem.doctorServices.map((service, index) => {
              return (
                <div className="service">
                  <div className="service-name">{t(service.slug)}</div>
                  <div className="service-price-time">
                    <div className="service-price">{t(service.priceAsString)}</div>
                    <div className="price-time-separator" />
                    <div className="service-time">{`${t(service.duration)} min`}</div>
                  </div>
                </div>
              );
            })}
            {listItem.doctorServices.length < 2 && <div className="service empty" />}
          </div>
          <div className="appointment-buttons">
            {(listItem.availability === 'online' || listItem.availability === 'busy') && (
              <DoctorCardCallButton status={listItem.availability} onClickEvent={onCall} />
            )}
            <DoctorCardAppointmentButton
              listItemSchedule={listItem}
              status={listItem.availability}
              onClickEvent={onCall}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
